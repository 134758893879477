import { useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import PhoneNumberForm from './ChangePhoneNumber';
import ChangeEmail from './ChangeEmail';
import ChangeName from './ChangeName';
import { firestore } from '@tabletuck/firebase-services';
import { environment } from '../../../environments/environment';
import { useSelector } from 'react-redux';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';

const PersonalInfo = () => {
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openNameDialog, setOpenNameDialog] = useState(false);

  const [openPhoneDialog, setOpenPhoneDialog] = useState(false);
  // const [user, setUser] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.authReducer.userData);
  const [phoneNumber, setPhoneNumber] = useState(''); // Replace with your actual phone number

  const formatPhoneNumber = (number) => {
    const digitsOnly = number?.replace(/\D/g, '');
    const countryCode = digitsOnly?.slice(1, 4);
    const areaCode = digitsOnly?.slice(4, 7);
    const subscriberNumber = digitsOnly?.slice(7);

    return `(${countryCode}) ${areaCode}-${subscriberNumber}`;
  };
  let formattedPhoneNumber = formatPhoneNumber(user?.phoneNumber);
  const [t, i18n] = useTranslation()

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: '30px', marginBottom: '30px' }}
    >
      <Box
        sx={{
          width: ' 100%',
          maxWidth: '100%',
        }}
      >
        <p style={{ fontWeight: 'bold', marginTop: '5px' }}>{t("profilee.name")}</p>
        <Grid item xs={12}>
          <div
            style={{
              padding: '11px 14px',
              border: '1px solid rgba(121,121,121,0.5)',
              borderRadius: '14px',
              height: '52px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#F9F9F9',
            }}
            onClick={() => setOpenNameDialog(true)}
          >
            <Typography>{user?.fullName}</Typography>
            <EditIcon style={{ color: environment.mainColor }} />
          </div>
        </Grid>
      </Box>
      <Box
        sx={{
          width: ' 100%',
          maxWidth: '100%',
          marginTop: '25px',
          marginBottom: '25px',
        }}

      >
        <p style={{ fontWeight: 'bold', marginTop: '5px' }}>{t("profilee.phone")}</p>
        <Grid item xs={12}>
          <div
            style={{
              padding: '11px 14px',
              border: '1px solid rgba(121,121,121,0.5)',
              borderRadius: '14px',
              height: '52px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#F9F9F9',
            }}
            onClick={() => setOpenPhoneDialog(true)}
          >

            <Typography style={{ direction: "initial" }}
            > {formattedPhoneNumber}</Typography>
            <EditIcon style={{ color: environment.mainColor }} />
          </div>
        </Grid>
      </Box>
      <Box
        sx={{
          width: ' 100%',
          maxWidth: '100%',
        }}
      >
        <p style={{ fontWeight: 'bold', marginTop: '5px' }}>{t("profilee.email")}</p>
        <Grid item xs={12}>
          <div
            style={{
              padding: '11px 14px',
              border: '1px solid rgba(121,121,121,0.5)',
              borderRadius: '14px',
              height: '52px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#F9F9F9',
            }}
            onClick={() => setOpenEmailDialog(true)}
          >
            <Typography>{email ? email : user?.email}</Typography>
            <EditIcon style={{ color: environment.mainColor }} />
          </div>
        </Grid>
      </Box>
      {user && (
        <ChangeEmail
          onClose={() => setOpenEmailDialog(false)}
          open={openEmailDialog}
          setEmail={setEmail}
          user={user}
          email={email}
        />
      )}
      {user && (
        <PhoneNumberForm
          onClose={() => setOpenPhoneDialog(false)}
          open={openPhoneDialog}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          user={user}
        />
      )}
      {user && (
        <ChangeName
          user={user}
          setName={setName}
          onClose={() => setOpenNameDialog(false)}
          open={openNameDialog}
          name={name}
        />
      )}
    </Container>
  );
};

export default PersonalInfo;
