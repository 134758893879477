import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  useTheme,
  CardContent,
  CardMedia,
  Card,
  Hidden,
  Divider,
  Dialog,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import reviewschool from '../../../assets/reviewschool.png';
import { useNavigate, useParams } from 'react-router-dom';
import banner from '../../../assets/banner.png';
import MenuTTdemo from '../../../assets/MenuTTdemo.png';
import menuAlyosef from '../../../assets/alyousef/Menu Image.png';
import TableTuck from '../../../assets/menufilfil.png';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';

import { Box } from '@mui/system';
import { Link } from 'react-scroll';
import './index.css';
import { useSelector } from 'react-redux';
import CustomizeDialoge from '../../components/CustomizeDialoge/CustomizeDialoge';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../../../../libs/components/src';
import {
  menues,
  res,
  setModifiers,
  setStores,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Utils/UtilsFunctions';
import Loading from 'react-fullscreen-loading';
import { Helmet } from 'react-helmet';
import { FiMinusSquare } from 'react-icons/fi';
function MenuItems2() {
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [cat, setCat] = useState([]);
  const [custm, setcustom] = useState('');
  const [extraquantity, setExtraquantity] = useState({});
  const [extraQuantity, setExtraQuantity] = useState({});
  const [defs, setDefs] = useState([]);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const [data, setData] = React.useState([]);
  const [openn, setOpenn] = useState(false);
  const params = useParams();
  const [enableAdd, setEnableAdd] = useState(false);
  let foundType = false; // Track if 'type' category is found
  const userData = useSelector((state) => state.authReducer.userData);
  const menuess = useSelector((state) => state.authReducer.menues);
  const [menuItems, setMenuItems] = useState(null);
  const storess = useSelector((state) => state.shopReducer.stores);
  const [store, setStore] = useState([]);
  const [type, setType] = useState('Popular');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const activeMenu = useSelector((state) => state.authReducer.activeMenu);

  const allactiveMenues = useSelector(
    (state) => state.authReducer.allactiveMenues
  );

  const items = useSelector((state) => state.shopReducer.currentItem);
  const [option, setOption] = useState(null);

  const categories = useSelector(
    (state) => state.shopReducer.currentCategories
  );
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [item, setItem] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [optionsPrice, setOptionsPrice] = useState(0);
  const user = useSelector((state) => state.authReducer.userData);

  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const [storeId, setStoreId] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const stores = useSelector((state) => state.shopReducer.stores);
  const navRef = useRef(null);

  const getMenues = async () => {
    try {
      setLoading(true);
      const apiUrl = `${environment?.apiUrl}/getMenu?restaurantId=${environment?.restaurantId}`;

      const response = await fetchData(apiUrl, 'get');
      dispatch(res(response.data));

      if (stores.length === 1) {
        const updatedUser = {
          ...userData,
          defaultStore: stores[0].id,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[stores[0]?.id]?.menus));
      } else {
        const updatedUser = {
          ...userData,
          defaultStore: userData.defaultStore,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[userData.defaultStore]?.menus));
      }
    } catch (error) {
      console.log('message Error:', error.response?.data?.message);
      // handleToaster('error' , error.response.data.message || error.response.data || "error in get menues" )
      console.log('FetchData Error:', error);
    }
    setLoading(false);
  };
  const getModifierss = async () => {
    try {
      let modifiersRef = firestore.collection('modifier_groups');
      let list = [];
      const modifierList = await modifiersRef.get();
      for (let index = 0; index < modifierList?.docs?.length; index++) {
        let element = modifierList?.docs[index]?.data();
        element.id = modifierList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setModifiers(list));
      // setModifiersGroups(modifiers);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNav = (direction) => {
    if (navRef.current) {
      if (direction === 'left') {
        navRef.current.scrollLeft -= 500;
      } else {
        navRef.current.scrollLeft += 500;
      }
    }
  };

  const handleOpenn = () => {
    setId(id);
    setOpenn(true);
    setItem(item);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          storess?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };
  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraquantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item.modifier_group_ids.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setcustom((c) => (c ? c + `, ${opt.title}` : opt.title));
              setDefs((defs) =>
                defs.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
        setOptionsPrice(o > 0 ? o : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getModifiers = async () => {
    setData(modifiers);
  };

  const handleOpen = (id, item) => {
    setId(id);
    setOpen(true);
    setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = async (value) => {
    setType(value);
  };

  useEffect(() => {
    getStoreDelivery();
  }, [outofStockDelivery]);

  useEffect(() => {
    if (items?.length) {
      getDefaults();
    }
  }, [id, items?.length]);

  useEffect(() => {
    getModifiers();
    getModifierss();
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpen(false);
      } else {
        let item = items?.find((item) => item?.id === params?.id);
        handleOpen(item?.id, item);
      }
    })();
  }, [params.id]);

  useEffect(() => {
    menuess?.map((c) =>
      c.categories?.map((category, index) => {
        if (index == 1 && c?.categories[0]?.items?.length == 0) {
          setType(c?.categories[1]?.id);
        }
      })
    );
  }, [menuess]);

  useEffect(() => {
    if (menuess === undefined) {
      setLoading(true);
      getMenues();
      console.log(storess);
    }
    // setLoading(false);
  }, [storess, menuess]);

 

  return (
    <>
      {environment?.restaurantId === 'istanbulDk'  || environment.restaurantId === 'taqwasbakery' && (
        <Helmet>
          <title>Taqwa's Bakery and Restaurant | Menu</title>
        </Helmet>
      )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '450px',
          backgroundImage:
            environment.restaurantId === 'alyousef'
              ? `url(${menuAlyosef})`
              : environment.restaurantId === 'filfil'
              ? `url(${TableTuck})`
              : environment.restaurantId === 'ttDemo'
              ? `url(${MenuTTdemo})`
              : environment.restaurantId === 'istanbulDk'
              ? `url(${banner})`
              : environment.restaurantId === 'taqwasbakery'
              ? `url(${banner})`
              : `url(${reviewschool})`,
          // MenuTTdemo

          backgroundRepeat: 'no-repeat',
        }}
      ></div>

      <Container maxWidth={'lg'}>
        <Grid
          container
          style={{
            display: smDown ? 'unset' : 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={smDown ? 'h4' : 'h2'}
            style={{
              marginTop: '30px',
              fontFamily: `Hanalei Fill, cursive`,
              marginBottom: '20px',
              color: environment.mainColor,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Choose best of our menu
          </Typography>
        </Grid>
      </Container>
      <div className="menu-container">
        <div>
          {menuess?.length > 5 ? (
            <button onClick={() => handleNav('left')}>
              <p className="fa fa-lg fa-chevron-left">
                <KeyboardDoubleArrowLeftTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
        <div
          className="nav-items"
          ref={navRef}
          style={{
            width: '100%',
            // display: smDown ? 'unset' : 'flex',
            justifyContent: 'center',
          }}
        >
          {menuess?.map((c) => (
            <div>
              {c.categories?.length > 0 &&
                c.categories?.map((category, index) => (
                  <Grid
                    item
                    lg={4}
                    style={{
                      display: 'inline-block',
                      color: 'white',
                      textAalign: 'center',
                      padding: '14px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <Card
                      key={category.id}
                      style={{
                        border: '2px solid lightgray',
                        borderRadius: '20px',
                        borderColor:
                          type === category?.id && category?.items?.length !== 0
                            ? environment.mainColor
                            : 'gray',
                        width: smDown ? '120px' : '140px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleChangeType(category.id);
                        setEnableAdd(false);
                      }}
                    >
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={category.category_image_thumb_url}
                          alt={category.category_image_thumb_url}
                          style={{ width: '60px', height: '60px' }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 'bold',
                            color: 'black',
                          }}
                        >
                          {category.name?.length > 12
                            ? category.name.slice(0, 12)
                            : category.name}
                          {category.name?.length > 12 ? '...' : ''}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </div>
          ))}
        </div>
        <div>
          {menuess?.length > 5 ? (
            <button onClick={() => handleNav('right')}>
              <p className="fa fa-lg fa-chevron-right">
                <KeyboardDoubleArrowRightTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>

      {menuess?.map((c) => (
        <div>
          {c.categories?.length > 0 &&
            c.categories?.map(
              (category, index) =>
                category.id === type && (
                  <Container maxWidth={'lg'}>
                    <Grid
                      key={category.id}
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: smDown ? '-10px' : '0px',
                        marginBottom: smDown ? '30px' : '60px',
                      }}
                    >
                      <Typography
                        variant={'h4'}
                        style={{
                          marginTop: '50px',
                          fontFamily: 'Abel',
                        }}
                      >
                        {category.name}
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '60%',
                          }}
                        >
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>

                          <Divider
                            style={{
                              marginBottom: '5px',
                              marginTop: '5px',
                              height: '2px',
                              backgroundColor: environment.mainColor,
                              width: '30%',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                          />
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>
                        </div>
                      </div>
                    </Grid>

                    <div id={category?.id} key={index}>
                      {
                        <>
                          {' '}
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            sx={{
                              display: { sm: 'block', lg: 'flex' },
                            }}
                          >
                            {category.items?.length > 0 &&
                              category.items?.map(
                                (item, index) =>
                                  // item.parent_menu_id === category.id &&
                                  item.item_state === 'available' && (
                                    <>
                                      {environment?.restaurantId ===
                                      'elmbrookschools' ? (
                                        <Grid
                                          key={item.id}
                                          item
                                          lg={3}
                                          sm={6}
                                          style={{
                                            marginTop: '30px',
                                            marginBottom: '50px',
                                            display: 'grid',
                                          }}
                                        >
                                          <div
                                            onClick={() =>
                                              handleOpen(item?.id, item)
                                            }
                                          ></div>
                                          <div className="menu-item-image-container">
                                            <div
                                              className="menu-item-image-circle"
                                              style={{
                                                backgroundColor: '#fadcbb',
                                                borderRadius: '100px',
                                                width: '200px',
                                                backgroundImage:
                                                  ' linear-gradient(to top, #FBF1EA, #FBF1EA, #FFFDFC, #FFFDFC, #FFFDFC)',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <img
                                                  src={item?.item_image_url}
                                                  alt="Menu Item"
                                                  style={{
                                                    height: '80%',
                                                    width: '80%',
                                                  }}
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  marginTop: '10px',
                                                }}
                                              >
                                                <Button
                                                  variant="contained"
                                                  style={{
                                                    backgroundColor: 'white',
                                                    color:
                                                      environment.primaryColor,
                                                    borderRadius: '30px',
                                                  }}
                                                  onClick={() =>
                                                    handleOpen(item?.id, item)
                                                  }
                                                >
                                                  Add to cart
                                                </Button>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                width: '64%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                              }}
                                            >
                                              <Typography
                                                variant="h5"
                                                style={{
                                                  color:
                                                    environment.primaryColor,
                                                }}
                                              >
                                                {item?.name}
                                              </Typography>
                                            </div>
                                          </div>
                                        </Grid>
                                      ) : (
                                        <>
                                          {' '}
                                          <Hidden smDown>
                                            <Grid item sm={6} lg={6}>
                                              <div
                                                className="menu-item-card"
                                                onClick={() =>
                                                  handleOpen(item?.id, item)
                                                }
                                              >
                                                <div className="menu-item-details">
                                                  <Grid item lg={8} xl={8}>
                                                    <Typography
                                                      variant={'body1'}
                                                      style={{
                                                        fontWeight: 'bold',
                                                        color: '#833720',
                                                        display: 'flex',
                                                      }}
                                                    >
                                                      {item.isCatering ? (
                                                        <>
                                                          <FiMinusSquare
                                                            style={{
                                                              marginTop: '4px',
                                                              marginRight:
                                                                '3px',
                                                            }}
                                                          />
                                                          {'Catering'}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </Typography>
                                                  </Grid>
                                                  <h6 className="menu-item-title">
                                                    {item?.name}
                                                  </h6>
                                                  <p className="menu-item-description">
                                                    {item?.description.length >
                                                    99
                                                      ? item?.description.slice(
                                                          0,
                                                          99
                                                        )
                                                      : item?.description}
                                                    {item?.description.length >
                                                    99
                                                      ? '...'
                                                      : ''}
                                                  </p>
                                                  {/* <p className="menu-item-price">
                                        ${item?.price}
                                      </p> */}
                                                </div>
                                                <div className="menu-item-image-container">
                                                  <img
                                                    src={item?.item_image_url}
                                                    alt="Menu Item"
                                                    className="menu-item-image"
                                                  />
                                                  <button className="menu-item-button">
                                                    <p
                                                      style={{
                                                        fontSize: '12px',
                                                      }}
                                                    >
                                                      {t('menu.add')}
                                                    </p>
                                                  </button>
                                                </div>
                                              </div>
                                            </Grid>
                                          </Hidden>
                                          {/* //in mobile */}
                                          {/* Text content */}
                                          <Hidden smUp>
                                            <Grid item sm={12} lg={12}>
                                              <div
                                                className="menu-item-card2"
                                                onClick={() =>
                                                  handleOpen(item?.id, item)
                                                }
                                                style={{
                                                  paddingBottom: '0px',
                                                }}
                                              >
                                                <div className="menu-item-details">
                                                  <h6
                                                    className="menu-item-title"
                                                    style={{
                                                      fontWeight: 'bold',
                                                      fontSize: '.9rem',
                                                      marginTop: '-12px',
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </h6>
                                                  {/* <p
                                                    className="menu-item-price"
                                                    style={{
                                                      fontWeight: 'bold',
                                                      fontSize: '.6rem',
                                                      marginTop: '-5px',
                                                    }}
                                                  >
                                                    ${item?.price}
                                                  </p> */}
                                                  <p
                                                    className="menu-item-description"
                                                    style={{
                                                      marginTop: '10px',
                                                      fontSize: '.6rem',
                                                      paddingBottom: '0px',
                                                      // ====
                                                    }}
                                                  >
                                                    {item?.description.length >
                                                    99
                                                      ? item?.description.slice(
                                                          0,
                                                          99
                                                        )
                                                      : item?.description}
                                                    {item?.description.length >
                                                    99
                                                      ? '...'
                                                      : ''}
                                                  </p>
                                                </div>
                                                <div className="menu-item-image-container2">
                                                  <img
                                                    src={item?.item_image_url}
                                                    alt="Menu Item"
                                                    className="menu-item-image"
                                                  />
                                                  <button
                                                    className="menu-item-button"
                                                    style={{
                                                      paddingRight: '10px',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: '11px',
                                                      }}
                                                    >
                                                      {t('menu.add')}
                                                    </p>
                                                  </button>
                                                </div>
                                              </div>
                                            </Grid>
                                          </Hidden>
                                          {/* Image */}
                                          {/* Divider */}
                                          {/* ====== */}
                                        </>
                                      )}

                                      {item?.id === id ? (
                                        <CustomizeDialoge
                                          open={open}
                                          handleClose={handleClose}
                                          option={option}
                                          c={item}
                                          priceToShow={
                                            item?.price + optionsPrice1
                                          }
                                          data={data}
                                          defs={defs}
                                          defaultPrices={optionsPrice1}
                                          extraQuantity={extraQuantity}
                                          setExtraQuantity={setExtraQuantity}
                                          outofStockDelivery={
                                            outofStockDelivery
                                          }
                                          openn={openn}
                                          handleClosee={handleClosee}
                                          handleOpenn={handleOpenn}
                                          enableAdd={enableAdd}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )
                              )}
                          </Grid>
                        </>
                      }
                    </div>
                  </Container>
                )
            )}
        </div>
      ))}

      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </>
  );
}
export default MenuItems2;
