import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  Paper,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import footer from '../../../assets/background.png';
import { CustomButton } from '@tabletuck/components';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Radio from '@mui/material/Radio';
import WorkIcon from '@mui/icons-material/Work';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { red } from '@mui/material/colors';
import PaymentsDialog from './dialog';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import HomeIcon from '@mui/icons-material/Home';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import NewPaymet from './NewPaymet';
import axios from 'axios';
import OrderSummary from '../../components/Checkout/OrderSummary';
import { useDispatch } from 'react-redux';
import {
  auth,
  firestore,
  RootFirestore,
} from '../../../../../../libs/firebase-service/src';
import { getTimeZones, getTomorrow, getTomorrowScheduleLater } from './utils';
import Snipper from '/libs/components/src/lib/snipper/Snipper';
import SuccessDialog from './successDialog';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';

import {
  clearCartData,
  setCart,
  setUser,
  UdpateNote,
} from '../../../../../../libs/redux-slices/src';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';
import { ToastContainer } from 'react-toastify';
const tomorrow = 'tomorrow';
const today = 'today';
const afterTomorrow = 'afterTomorrow';
const ConfirmOrderPage = ({ typo, store }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [slots, setSlots] = useState({});
  const [StoreData, setStoreData] = useState({});
  const [range, setRange] = useState({
    today: '',
    tomorrow: '',
    afterTomorrow: '',
  });
  const [openSucess, setOpenSuccess] = useState(false);
  const [selectedTime, setSelectedTime] = useState({});
  const [unavailableNow, setUnAvailableNow] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [asap, setAsap] = useState('20-30 min');
  const [prev, setPrev] = useState('');
  const stripe = useStripe();
  const [err, setErr] = React.useState('');
  const elements = useElements();
  const [paymentRequest, setReqestPayment] = React.useState();
  const [change, setChange] = React.useState(null);
  const [realTime, setRealTime] = useState(null);
  const defauldAdd = useSelector((state) => state.shopReducer.defauldAdd);
  const defaultStore = useSelector((state) => state.shopReducer.defaultStore);

  const defaultStorefee = useSelector(
    (state) => state.shopReducer.defaultStorefee
  );

  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  const { orderType, typeAddress, code, storeId, discount } = useSelector(
    (state) => state.shopReducer.cartData
  );

  const deliveryStore = useSelector((state) => state.shopReducer.deliveryStore);
  const note = useSelector((state) => state.shopReducer.note);
  const deliveryAddress = useSelector(
    (state) => state.shopReducer.deliveryAddress
  );
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [typeTip, setTypeTip] = React.useState('b');
  // const [user, setUser] = useState();
  const [scheduleOrderEnabled, setScheduleOrderEnabled] = useState(false);
  const [tipAmount, setTipsAmount] = useState(0);
  const [otherTip, setOtherTip] = useState(0);
  const [stateTxt, setStateTxt] = useState('');
  const [closed, setFees] = useState(0);
  const [userCard, setUserCard] = useState();
  const [dateOfOrder, setDateOfOrder] = useState(null);
  const token = useSelector((state) => state.authReducer.token);
  const [loading, setLoading] = useState(false);
  const [openD, setD] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCredit, setOpenCredit] = useState(false);
  const tableNo = useSelector((state) => state.shopReducer.tableNo);
  const [data, setData] = useState([]);
  const [storeIdD, setStoreId] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const user = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const [env, setEnv] = useState([]);
  const [tom, setTom] = useState([]);
  const [errAmount, setErrAmount] = useState('');
  const [classroomNumber, setClassroomNumber] = useState('');
  const [cash, setCash] = useState(false);
  const hasCatering = user?.bagItems.some((item) => item.isCatering);
  const [sendTime, setSendTime] = useState(hasCatering ? tomorrow : today);
  const [cards, setCards] = useState([]);
  const [openStripeDailog, SetopenStripeDailog] = useState(false);
  const [activeorders,setActiveorders] = useState("")

  const handleClassroomNumberChange = (event) => {
    setClassroomNumber(event.target.value);
  };
  const getENV = async () => {
    const doc = await RootFirestore.collection('environment')
      .doc(environment.restaurantId)
      .get();
    setEnv(doc.data());
  };

  useEffect(() => {
    getENV();
  }, []);

  useEffect(() => {
    stores.filter((store) =>
      store.id === storeId
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, []);
  const getOrders = async () => {
    try {
        let StoreRef = firestore
            .collection('orders')
            .where('uid', '==', auth?.currentUser?.uid)
            .orderBy('created_at', 'desc')
            .limit(50);

        let list = [];
        const allOrderStatusArray = []; // Array for all 'order_status' values
        const ActiveOrderStatusArray = []; // Array for filtered 'order_status' values

        const StoresList = await StoreRef.get();
        for (let index = 0; index < StoresList.docs.length; index++) {
            let element = StoresList.docs[index].data();
            element.id = StoresList.docs[index].id;
            list.push(element);

            // Push 'order_status' into the array
            allOrderStatusArray.push(element.order_status);

            // Check if 'order_status' is 'confirmed', 'new', or 'ready_to_pickup'
            if (['confirmed', 'new', 'ready_to_pickup'].includes(element.order_status)) {
              ActiveOrderStatusArray.push(element.order_status);
            }
        }

        setData(list);

   
        setActiveorders(ActiveOrderStatusArray)
        setLoading(false);
    } catch (err) {
      console.log('errror in get orders', err);
      setLoading(false);
    }
};



  const getUser = async () => {
    setStoreId(user?.defaultStore);
  };

  useEffect(() => {
    getStoreData();
  }, [storeId]);

  const getStoreData = async () => {
    if (storeId) {
      const foundStore = stores.find((store) => store.id === storeId);
      if (foundStore) {
        setScheduleOrderEnabled(foundStore?.schedule_order_enabled);
        setStoreData(foundStore);
      }
    }
  };
  useEffect(() => {
    getUser();
    getOrders();
  }, []);
  const onConfirmClover = async (c) => {
    setLoading(true);
    setErr('');
    try {
      const types = { googlePay: 'google_pay', applePay: 'apple_pay' };
      const type = 'clover';
      const deliveryOrPickup = orderType;
      const storeId =
        orderType == 'delivery' || orderType == 'noDriverDelivery'
          ? deliveryStore
          : storeIdD;
      const billingZipcode = userCard?.address_zip ? userCard?.address_zip : '';
      const paymentData = c;
      const cartItems = user?.bagItems;
      const phoneNumber = user?.phoneNumber;
      //note
      const deliveryId = deliveryAddress;

      let dateOrderScheduling;
      let isScheduling;
      if (orderType === 'dinein') {
        dateOrderScheduling = null;
        isScheduling = false;
      }

      if (orderType !== 'dinein') {
        isScheduling = selectedValue !== 'a';
        dateOrderScheduling = dateOfOrder;
        if (!dateOrderScheduling && isScheduling) {
          setErr('Please choose a date');
          setLoading(false);
          return;
        }
      }

      const uid = auth?.currentUser.getIdToken().then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        const { data } = await axios.post(
          `${environment?.apiUrl}/checkoutV3?restaurantId=${environment.restaurantId}`,
          {
            // auth.currentUser.uid,
            storeId,
            type,
            deliveryOrPickup,
            code,
            note,
            cartItems,
            phoneNumber,
            paymentData,
            billingZipcode,
            deliveryId,
            isScheduling,
            tableNo,
            dateOrderScheduling,
            tipAmount,
            cash,
          },

          { headers }
        );
        await firestore.collection('users').doc(auth?.currentUser?.uid).set(
          {
            bagItems: [],
          },
          { merge: true }
        );
        dispatch(setCart([]));
        const updatedUser = { ...user, bagItems: [] };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(UdpateNote(''));
        setLoading(false);
        setOpenSuccess(true);
      });
    } catch (e) {
      setLoading(false);
      console.log(e.message);
      setErr(
        e.response?.data?.message || 'Something went wrong try again later.'
      );
    }
  };
  const onConfirm = async () => {
    closeConfirmDialog();
    setLoading(true);
    setErr('');
    try {
      const types = { googlePay: 'google_pay', applePay: 'apple_pay' };
      const type = +change !== NaN ? 'card' : types[change];
      const deliveryOrPickup =
        orderType === 'delivery' ? env?.delivery_type : orderType;
      const storeId = orderType == 'delivery' ? deliveryStore : storeIdD;
      const billingZipcode = userCard?.address_zip ? userCard?.address_zip : '';
      const paymentData = userCard?.id ? userCard?.id : '';
      const cartItems = user?.bagItems;
      const phoneNumber = user?.phoneNumber;
      const classroom = classroomNumber;
      //note

      const deliveryId = deliveryAddress;
      // if (env?.paymentFlow === 'stripe') {
      if (orderType != 'dinein') {
        if (change == 'newCard' || !selectedValue || !userCard) {
          setErr('please choose a card');
          setLoading(false);
          return;
        }
      }

      let dateOrderScheduling;
      let isScheduling;
      if (orderType === 'dinein') {
        dateOrderScheduling = null;
        isScheduling = false;
        if (change == 'newCard' || !userCard) {
          setErr('please choose a card');
          setLoading(false);
          return;
        }
      }

      if (orderType !== 'dinein') {
        isScheduling = selectedValue !== 'a';
        dateOrderScheduling = dateOfOrder;
        if (!dateOrderScheduling && isScheduling) {
          setErr('Please choose a date');
          setLoading(false);
          return;
        }
      }
      auth?.currentUser.getIdToken().then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        
        await fetchData(
          `${environment?.apiUrl}/checkoutCloverLive?restaurantId=${environment.restaurantId}`,
          'post',
          {
            uid: auth?.currentUser?.uid,
            storeId,
            type,
            deliveryOrPickup,
            code,
            note,
            cartItems,
            phoneNumber,
            paymentData,
            billingZipcode,
            deliveryId,
            isScheduling,
            tableNo,
            dateOrderScheduling,
            tipAmount,
            cash,
          },
          headers
        )
          .then(async (c) => {
            await firestore.collection('users').doc(auth?.currentUser?.uid).set(
              {
                bagItems: [],
              },
              { merge: true }
            );
            dispatch(setCart([]));
            const updatedUser = { ...user, bagItems: [] };
            dispatch(setUser({ userData: updatedUser }));
            dispatch(UdpateNote(''));
            setLoading(false);
            setOpenSuccess(true);
          })
          .catch((e) => {
            if (
              typeof e.response.data.err === 'string' &&
              (e.response.data.err === 'FAIL_STRIPE' || e.response.data.err.includes('stripe'))
            ) {
              SetopenStripeDailog(true);
              console.log('fail stripe');
              // handleToaster('error', e.response.data.message);
            } else {
              handleToaster(
                'error',
                e.response.data.message ||
                  e.response.data.shortMessage ||
                  e.response.data.err ||
                  e.response.data ||
                  e.response ||
                  'Something went wrong'
              );
            }
            console.log('api error catch', e);
            setLoading(false);
          });
      });
      // }
      //  else {
      //   let dateOrderScheduling;
      //   let isScheduling;
      //   if (orderType === 'dinein') {
      //     dateOrderScheduling = null;
      //     isScheduling = false;
      //   }
      //   await axios
      //     .post(
      //       `https://us-central1-wl-restaurants.cloudfunctions.net/api/verifyOrder?restaurantId=${environment.restaurantId}`,
      //       {
      //         uid: auth?.currentUser?.uid,
      //         storeId,
      //         type,
      //         deliveryOrPickup,
      //         code,
      //         note,
      //         cartItems,
      //         phoneNumber,
      //         paymentData,
      //         billingZipcode,
      //         deliveryId,
      //         isScheduling,
      //         tableNo,
      //         dateOrderScheduling,
      //         tipAmount,
      //       }
      //     )
      //     .then((result) => {
      //       if (result.status === 201) {
      //         console.warn('verified');
      //         setLoading(false);
      //         setOpenCredit(true);
      //       } else {
      //         console.warn(result.data.eror.message);
      //         setLoading(false);
      //       }
      //     })
      //     .catch((e) => {
      //       console.error(e.response?.data?.message ?? 'Something went wrong.');
      //       setErrAmount(e.response?.data?.message);
      //       setLoading(false);
      //     });
      // }
    } catch (e) {
      setLoading(false);
      console.log(e);
      setErr(
        e.response?.data?.message || 'Something went wrong try again later.'
      );
    }
  };

  const onSelect = (value, cart = null) => {
    setUserCard(cart);
    setChange(value);
    switch (value) {
      case 'newCard':
        setOpenCredit(true);
        setD(true);
        return;
      case '0' || '1' || '2' || '3' || '5' || '6' || '7':
        setUserCard(cart);
        setD(false);
        return;
      case 'googlePay':
        askPayment();
        setD(false);
        return;
      case 'applePay':
        askPayment();
        setD(false);
        return;
      default:
        setD(false);
        return;
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    // setSelectedTime({})
    // setRange('')
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenCredit = () => {
    setOpenCredit(true);
    // setSelectedTime({})
    // setRange('')
  };

  const handleCloseCridit = () => {
    setOpenCredit(false);
  };
  const askPayment = async () => {
    // if(!stripe||!elements) return
    // setErr('payment check')
    try {
      const pr = stripe.paymentRequest({
        currency: 'usd',
        country: 'US',
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          label: 'demo label',
          amount: 1,
        },
      });
      const res = await pr.canMakePayment();
      if (res) {
        setReqestPayment(pr);

        pr.on('paymentmethod', async (e) => {
          // send intent to server
          const { data } = await axios.post(
            `${environment?.apiUrl}/paymentIntents?restaurantId=${environment.restaurantId}`,
            {
              amount: 5,
              token: e.paymentMethod.id,
            }
          );
          // wait to client secret key and send body with usd
          // confirm payment on client
          const { clientSecret } = data;
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            {
              handleActions: false,
            }
          );
          if (error) {
            e.complete('fail');
            return;
          }
          e.complete('success');
          if (paymentIntent.status === 'requires_action') {
            const { error: confirmError } =
              stripe.confirmCardPayment(clientSecret);
            if (confirmError) {
              // The payment failed -- ask your customer for a new payment method.
              setErr('failed! please try another payment method');
            } else {
              // The payment has succeeded.
              setErr('Success');
            }
          }
          setErr('Success');
        });
      } else {
        // setErr(' Please make sure that your device supports this service.');
      }
    } catch (e) {
      console.log(e.message);
      setErr(e.message);
    }
  };
  React.useEffect(() => {
    askPayment();
  }, [stripe, elements]);

  const isActive = async () => {
    setStateTxt('');
    let restId =
      orderType === 'delivery' || orderType === 'noDriverDelivery'
        ? deliveryStore
        : storeIdD;
    const res = await getTimeZones(restId, setErr);
    if (res === false) {
      setStateTxt('Only available during business hours');
      return setIsBusy(true);
    } else if (res && res?.asap == false) {
      
      setStateTxt('Currently Closed');
    }
    let dataofRespond = convertTimeSlots(res);
    setSlots(dataofRespond);
  };

  function convertTimeSlots(data) {
    const convertedData = {};
    for (const key in data) {
      if (key === 'asap') {
        convertedData.asap = data.asap;
      } else {
        const dayName =
          key === 'today'
            ? 'today'
            : key === 'tomorrow'
            ? 'tomorrow'
            : 'afterTomorrow';
        const daySlots = data[key].map((slot, index) => {
          const timeRange = slot.time.split(' - ');
          return `${timeRange[0]} - ${timeRange[1]}`;
        });
        convertedData[dayName] = daySlots;
      }
    }
    return convertedData;
  }

  // =========================================

  function convertTimeSlots(data) {
    const convertedData = {};
    for (const key in data) {
      if (key === 'asap') {
        convertedData.asap = data.asap;
      } else {
        const dayName =
          key === 'today'
            ? 'today'
            : key === 'tomorrow'
            ? 'tomorrow'
            : 'afterTomorrow';
        const daySlots = data[key].map((slot, index) => {
          const timeRange = slot.time.split(' - ');
          return `${timeRange[0]} - ${timeRange[1]}`;
        });
        convertedData[dayName] = daySlots;
      }
    }
    return convertedData;
  }

  // =========================================

  const handleChange = (event) => {
    unavailableNow
      ? setSelectedValue(false)
      : setSelectedValue(event.target.value);
  };


  useEffect(() => {
    // if (orderType != 'dinein') {
    isActive();
    // }
  }, [storeId, deliveryStore, storeIdD]);

  const onTimeSelected = (data, index) => {
    setRealTime(sendTime);
    handleClose();
    let hour = +data.split('-')[0].split(':')[0];
    const time = new Date();

    const Timeindex = data.indexOf('m');
    const timeRange = data[Timeindex - 1] + data[Timeindex];
    const parts = data.split('-');
    // Get the part after the hyphen (trim any leading/trailing spaces)
    const secondPart = parts[0].trim();
    // Check if it contains "AM" or "PM"
    const isPM = secondPart.includes('PM');
    if (isPM) {
      if (hour == 12) {
        hour = 12;
      } else if (hour !== 12) {
        hour = hour + 12;
      }
    } else {
      if (hour === 12) {
        hour = 1;
      }
    }

    if (sendTime == tomorrow) {
      time.setDate(time.getDate() + 1);
    } else if (sendTime == afterTomorrow) {
      time.setDate(time.getDate() + 2);
    }
    time.setHours(hour);
    time.setMinutes(0);
    setDateOfOrder(time);
    setSelectedTime({ index, data, sendTime });
    setPrev(sendTime);
    setRange(index);
  };
  const [t, i18n] = useTranslation();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
  };
  useEffect(() => {
    const isAvailable = getAvailableTimeSlots(StoreData?.endTime);
    setUnAvailableNow(isAvailable);
  }, [StoreData?.endTime, StoreData]);
  function getAvailableTimeSlots(endTime) {
    const currentTime = new Date();
    const currentTimestamp = currentTime?.getTime();
    // Constructing a JavaScript Date object from Firestore-like object
    const dateObject = endTime
      ? new Date(endTime.seconds * 1000 + endTime.nanoseconds / 1000000)
      : null;
    if (dateObject instanceof Date) {
      const endTimestamp = dateObject?.getTime();
      if (!isNaN(endTimestamp)) {
        if (endTimestamp < currentTimestamp) {
        
          return false; // If endTime is in the past (unavailable)
        } else {
         
          return true; // If endTime is in the future (available)
        }
      } else {
        return false; // Invalid endTime
      }
    } else {
  
      return false; // Treat as available if endTime is undefined or not a Date object
    }
  }

  const handleCloseDailog = () => {
    SetopenStripeDailog(false);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Dialog open={confirmDialog} onClose={closeConfirmDialog}>
        <DialogTitle>Order Support:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedValue === 'a'
              ? `Great! Are you sure you want to choose our ASAP ${orderType} option?`
              : `  Are you sure you want your order to be ${
                  orderType === 'delivery' || orderType === 'noDriverDelivery'
                    ? 'deliverd'
                    : 'pickedup'
                } ${
                  realTime === 'today'
                    ? 'Today'
                    : realTime === 'tomorrow'
                    ? 'Tomorrow'
                    : getTomorrow().after.day +
                      ', ' +
                      getTomorrowScheduleLater()
                }: ${selectedTime.data}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} style={{ color: 'grey' }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if ((change == 'newCard', !selectedValue, !userCard)) {
                setErr('Please choose a card');
              } else onConfirm();
              closeConfirmDialog();
            }}
            style={{ color: environment?.mainColor }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          padding: '120px 0',
          position: 'relative',
          backgroundImage: `url(${footer})`,
          marginBottom: '50px',
        }}
      >
        <Container maxWidth={'xl'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
            }}
          >
            <Typography
              variant="h3"
              style={{
                fontFamily: 'Caveat',
                fontWeight: 'bold',
                fontSize: '50px',
                color: environment?.mainColor,
              }}
            >
              {t('confirmPage.details')}
            </Typography>
          </div>
          <Divider style={{ marginBottom: '60px' }} />
        </Container>

        <Container maxWidth={'xl'} style={{ marginBottom: '20px' }}>
          <Grid container>
            <Grid
              item
              lg={8}
              sm={12}
              // style={{marginLeft:smDown&&20, }}
              sx={{ maxWidth: { xs: '100%', md: '100%', lg: '60%' } }}
            >
              <Paper>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    // marginTop: '20px',
                    flexDirection: 'row',
                  }}
                >
                  {orderType === 'delivery' ||
                  orderType === 'noDriverDelivery' ? (
                    <Button
                      disableRipple={true}
                      disabled={orderType === 'Delivery'}
                      style={{
                        width: '100%',
                        cursor: 'default',
                        backgroundColor: environment.mainColor,
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                      variant={'contained'}
                    >
                      {' '}
                      {t('confirmPage.Delivery')}
                    </Button>
                  ) : orderType === 'dinein' ? (
                    <Button
                      disableRipple={true}
                      disabled={orderType === 'dinein'}
                      style={{
                        width: '100%',
                        cursor: 'default',
                        backgroundColor: environment.mainColor,
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                      variant={'contained'}
                    >
                      {t('confirmPage.Dinein')}
                    </Button>
                  ) : (
                    <Button
                      disableRipple={true}
                      disabled={orderType === 'pickup'}
                      style={{
                        width: '100%',
                        cursor: 'default',
                        backgroundColor: environment.mainColor,
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                      variant={'contained'}
                    >
                      {t('confirmPage.pickUp')}
                    </Button>
                  )}
                </Box>
                <Divider />
                <Box
                  sx={{
                    padding: {
                      xs: '1.8rem 2.5rem 2rem 2.5rem',
                      md: '1.8rem 2.5rem 2rem 2.5rem',
                      lg: '1.8rem 4.5rem 2rem 4.5rem',
                    },
                  }}
                >
                  {orderType === 'delivery' ||
                  orderType === 'noDriverDelivery' ? (
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={4}>
                        <Typography
                          component="p"
                          variant="h6"
                          style={{ fontWeight: 'bold' }}
                        >
                          1 {t('confirmPage.ShippingAddress')}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} style={{ marginTop: '5px' }}>
                        <p
                          class="text-gray-900 text-base "
                          style={{ color: 'dimgrey' }}
                        >
                          {user?.fullName}
                        </p>

                        <p
                          class="text-gray-900 text-base "
                          style={{ color: 'dimgrey' }}
                        >
                          {' '}
                          {defauldAdd}
                        </p>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={4}>
                        <Typography
                          component="p"
                          variant="h6"
                          style={{ fontWeight: 'bold' }}
                        >
                          1 {t('confirmPage.pickupAddress')}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} style={{ marginTop: '5px' }}>
                        <p
                          class="text-gray-900 text-base "
                          style={{ color: 'dimgrey', fontWeight: 'bold' }}
                        >
                          {defaultStore}
                        </p>

                        <p
                          class="text-gray-900 text-base "
                          style={{ color: 'dimgrey' }}
                        >
                          {' '}
                          {defaultStoreAdress}
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Divider style={{ marginTop: '50px' }} />

                  {/* {note && (
                  <h5 class="text-gray-900 text-xl font-medium mt-3.5">
                    Your notes:
                    <br />
                    {note}
                  </h5>
                )} */}
                  {/* {env?.paymentFlow === 'stripe' && ( */}
                  <Grid
                    item
                    sm={12}
                    lg={12}
                    sx={{
                      maxWidth: { xs: '100%', md: '100%', lg: '100%' },
                      marginTop: '10px',
                    }}
                  >
                    <div style={{ marginTop: '40px', marginBottom: '10px' }}>
                      <Typography
                        component="p"
                        variant="h6"
                        style={{ fontWeight: 'bold' }}
                      >
                        2 {t('confirmPage.payment')}
                      </Typography>
                    </div>
                    {/* <Divider /> */}
                    <PaymentsDialog
                      cards={cards}
                      setCards={setCards}
                      handleChange={setChange}
                      onSelect={onSelect}
                      change={change}
                      openCredit={openCredit}
                      setCredit={setOpenCredit}
                      setD={setD}
                      setSelectedCard={setUserCard}
                      cash={cash}
                      setCash={setCash}
                    />
                  </Grid>

                  {/* )} */}
                  {/* schedule order starts from here */}
                  <Divider style={{ marginTop: '50px' }} />

                  {orderType != 'dinein' && scheduleOrderEnabled && (
                    <>
                      {unavailableNow ? (
                        <></>
                      ) : (
                        <>
                          {' '}
                          <div
                            style={{ marginTop: '40px', marginBottom: '10px' }}
                          >
                            <Typography
                              component="p"
                              variant="h6"
                              style={{ fontWeight: 'bold' }}
                            >
                              3 {t('confirmPage.deliveryTime')}
                            </Typography>
                          </div>
                          <Box sx={{ width: '100%' }}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: smDown ? 'column' : 'row',
                                width: '100%',
                                margin: 0,
                              }}
                            >
                              {!hasCatering && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // flex:.5,
                                    width: smDown ? '350px' : '100%',

                                    marginBottom: smDown && 10,
                                  }}
                                >
                                  {!unavailableNow && slots?.asap ? (
                                    <Radio
                                      checked={selectedValue === 'a'}
                                      sx={{
                                        color: environment.mainColor,
                                        '&.Mui-checked': {
                                          color: environment.mainColor,
                                        },
                                      }}
                                      onChange={handleChange}
                                      value="a"
                                      name="radio-buttons"
                                      inputProps={{ 'aria-label': 'A' }}
                                    />
                                  ) : (
                                    <div style={{ width: 50 }} />
                                  )}

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      border: '2px solid lightgray',
                                      paddingLeft: '10px',
                                      borderRadius: '10px',
                                      backgroundColor:
                                        unavailableNow ||
                                        (!slots?.asap && 'lightgray'),
                                      marginLeft: unavailableNow && 20,
                                      paddingRight: '10px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        miWidth: '200px',
                                      }}
                                    >
                                      <div>
                                        <LocalShippingIcon
                                          fontSize="large"
                                          style={{
                                            color: environment.mainColor,
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <Typography variant="body1">
                                          {t('confirmPage.asap')} <br />
                                          <span style={{ color: 'gray' }}>
                                            {unavailableNow || !slots?.asap
                                              ? stateTxt
                                              : asap}
                                          </span>
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: smDown ? 350 : '100%',
                                  minWidth: '60%',
                                }}
                              >
                                {!unavailableNow && (
                                  <Radio
                                    checked={selectedValue === 'b'}
                                    sx={{
                                      color: environment.mainColor,
                                      '&.Mui-checked': {
                                        color: environment.mainColor,
                                      },
                                    }}
                                    onChange={handleChange}
                                    onClick={handleClickOpen}
                                    value="b"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                  />
                                )}
                                <div
                                  style={{
                                    border: '2px solid lightgray',
                                    paddingLeft: '10px',
                                    borderRadius: '10px',
                                    marginLeft: 10,
                                    backgroundColor: unavailableNow
                                      ? 'lightgray'
                                      : 'white',
                                  }}
                                >
                                  <div
                                    // container
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <div>
                                      <LocalShippingIcon
                                        fontSize="large"
                                        style={{ color: environment.mainColor }}
                                      />
                                    </div>
                                    <div>
                                      <Typography
                                        variant="body1"
                                        onClick={
                                          selectedValue === 'b'
                                            ? handleClickOpen
                                            : console.log('')
                                        }
                                        style={{
                                          cursor: 'pointer',
                                          padding: '3px',
                                        }}
                                      >
                                        {t('confirmPage.Schedulerforlater')}{' '}
                                        <br />
                                        <span style={{ color: 'gray' }}>
                                          {selectedTime.data
                                            ? `${
                                                realTime === 'today'
                                                  ? 'Today'
                                                  : realTime === 'tomorrow'
                                                  ? 'Tomorrow'
                                                  : getTomorrow().after.day +
                                                    ', ' +
                                                    getTomorrowScheduleLater()
                                              }: ${selectedTime.data}`
                                            : `${t(
                                                'confirmPage.pleaseChooseTime'
                                              )}`}
                                        </span>
                                        <br />
                                      </Typography>
                                      <Dialog
                                        onClose={handleClose}
                                        open={open}
                                        style={{ borderRadius: '250px' }}
                                        fullWidth={smDown}
                                      >
                                        <div
                                          style={{
                                            padding: '15px 20px 0px 0px',
                                            display: 'flex',
                                            justifyContent: 'end',
                                            borderRadius: '25px',
                                          }}
                                        >
                                          {' '}
                                          <CancelRoundedIcon
                                            style={{
                                              cursor: 'pointer',
                                              marginLeft: '15px',
                                              color: environment.mainColor,
                                            }}
                                            onClick={handleClose}
                                          />
                                        </div>
                                        <DialogTitle
                                          style={{
                                            display: 'grid',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            <Typography
                                              component="p"
                                              variant="h6"
                                              style={{ fontWeight: 'bold' }}
                                            >
                                              {' '}
                                              {t(
                                                'confirmPage.Schedulerforlater'
                                              )}
                                            </Typography>
                                          </div>
                                        </DialogTitle>
                                        <DialogContent dividers>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: smDown
                                                ? 'column'
                                                : 'row',
                                              flex: 1,
                                              width: '100%',
                                            }}
                                          >
                                            <Grid
                                              container
                                              style={{
                                                flex: 1,
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: smDown
                                                  ? 'row'
                                                  : 'column',
                                                alignItems: 'center',
                                                marginRight: !smDown && 30,
                                                marginBottom: 10,
                                              }}
                                            >
                                              {!hasCatering && (
                                                <Button
                                                  disableRipple={true}
                                                  style={{
                                                    // display: 'block',
                                                    width: smDown
                                                      ? '30%'
                                                      : '100%',
                                                    backgroundColor:
                                                      sendTime === today
                                                        ? environment.mainColor
                                                        : '#FBFAFA',
                                                    color:
                                                      sendTime === today
                                                        ? 'white'
                                                        : environment.mainColor,
                                                    fontWeight: 'bold',
                                                    marginRight: 3,

                                                    marginBottom: !smDown && 10,
                                                  }}
                                                  variant={'contained'}
                                                  onClick={() => {
                                                    setSendTime(today);
                                                  }}
                                                >
                                                  {' '}
                                                  {t('confirmPage.today')}
                                                </Button>
                                              )}

                                              <Button
                                                disableRipple={true}
                                                style={{
                                                  width: smDown
                                                    ? '30%'
                                                    : '100%',
                                                  marginRight: 5,
                                                  marginBottom: !smDown && 10,
                                                  padding: 5,
                                                  fontWeight: smDown
                                                    ? '600'
                                                    : 'bold',
                                                  backgroundColor:
                                                    sendTime === tomorrow
                                                      ? environment.mainColor
                                                      : '#FBFAFA',
                                                  color:
                                                    sendTime === tomorrow
                                                      ? 'white'
                                                      : environment.mainColor,
                                                }}
                                                variant={'contained'}
                                                onClick={() => {
                                                  setSendTime(tomorrow);
                                                }}
                                              >
                                                {' '}
                                                {t('confirmPage.tomorrow')}
                                              </Button>

                                              <Button
                                                disableRipple={true}
                                                style={{
                                                  width: !smDown && '100%',
                                                  fontWeight: 'bold',
                                                  backgroundColor:
                                                    sendTime === afterTomorrow
                                                      ? environment.mainColor
                                                      : '#FBFAFA',
                                                  color:
                                                    sendTime === afterTomorrow
                                                      ? 'white'
                                                      : environment.mainColor,
                                                }}
                                                variant={'contained'}
                                                onClick={() => {
                                                  setSendTime(afterTomorrow);
                                                }}
                                              >
                                                {' '}
                                                {getTomorrow().after.day +
                                                  ', ' +
                                                  getTomorrowScheduleLater()}
                                              </Button>
                                            </Grid>
                                            <Grid
                                              tyle={{
                                                flex: 1,
                                                width: '100%',
                                                flexDirection: 'row',
                                              }}
                                            >
                                              {slots &&
                                              slots[sendTime]?.length ? (
                                                slots[sendTime].map(
                                                  (item, index) => {
                                                    return (
                                                      <Button
                                                        key={index + 'ty'}
                                                        disableRipple={true}
                                                        style={{
                                                          display: 'block',
                                                          margin: 4,
                                                          width: 300,
                                                          fontWeight: 'bold',
                                                          borderColor:
                                                            '#BDC0C3',
                                                          backgroundColor:
                                                            range === index &&
                                                            sendTime == prev
                                                              ? 'gray'
                                                              : 'white',
                                                          color:
                                                            range === index &&
                                                            prev === sendTime
                                                              ? 'white'
                                                              : 'black',
                                                        }}
                                                        variant={'contained'}
                                                        onClick={() => {
                                                          onTimeSelected(
                                                            item,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        {' '}
                                                        {item}
                                                      </Button>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <Typography>
                                                  {sendTime === 'today'
                                                    ? 'Sorry no more hours for today'
                                                    : ' Sorry we are off at this day'}
                                                </Typography>
                                              )}
                                            </Grid>
                                          </div>
                                        </DialogContent>
                                      </Dialog>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {environment.restaurantId === 'elmbrookschools' &&
                    orderType === 'delivery' && (
                      <Grid
                        item
                        sm={12}
                        lg={12}
                        sx={{
                          maxWidth: { xs: '100%', md: '100%', lg: '100%' },
                          marginTop: '10px',
                        }}
                      >
                        <div
                          style={{ marginTop: '40px', marginBottom: '10px' }}
                        >
                          <Typography
                            component="p"
                            variant="h6"
                            style={{ fontWeight: 'bold' }}
                          >
                            4 Classroom
                          </Typography>
                        </div>
                        <Grid item sm={4}>
                          <TextField
                            label="Classroom Number"
                            variant="outlined"
                            fullWidth
                            value={classroomNumber}
                            onChange={handleClassroomNumberChange}
                          />
                        </Grid>
                      </Grid>
                    )}

                  {/* end of schedule order */}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              sm={12}
              lg={4}
              sx={{ maxWidth: { xs: '100%', md: '100%', lg: '40%' } }}
            >
              <OrderSummary
                typeTip={typeTip}
                setTypeTip={setTypeTip}
                otherTip={otherTip}
                setOtherTip={setOtherTip}
                setTipsAmount={setTipsAmount}
                tipAmount={tipAmount}
                defaultStore={defaultStore}
                defaultStorefee={defaultStorefee}
              />

              <Grid>
                {' '}
                <p style={{ color: 'red' }}>{errAmount}</p>
              </Grid>

              {orderType != 'dinein' ? (
                <Grid item>
                  <p
                    style={{
                      color: 'red',
                      alignSelf: 'flex-end',
                      textAlign: 'center',
                      width: '100%',
                      fontWeight: 'bold',
                      marginBottom: 10,
                      marginTop: '10px',
                      // marginRight:250,
                    }}
                  >
                    {err}
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // marginRight: 100,
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          backgroundColor: environment.mainColor,
                          width: 250,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: 20,
                          height: 40,
                          padding: 10,
                          borderRadius: 10,
                        }}
                      >
                        <p style={{ marginRight: 10 }}>
                          {t('confirmPage.please')}
                        </p>
                        <Snipper color={'white'} />
                      </div>
                    ) : unavailableNow ? (
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: 'lightgray',
                          color: 'black',
                          fontSize: 12,
                          fontWeight: 'bold',
                          padding: 10,
                          height: 50,
                          width: !smDown && 300,
                          paddingRight: '10px',
                        }}
                      >
                        {t('confirmPage.err')}
                      </Button>
                    ) : environment.restaurantId === 'elmbrookschools' &&
                      orderType === 'delivery' &&
                      classroomNumber == '' ? (
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: 'lightgray',
                          color: 'black',
                          fontSize: 12,
                          fontWeight: 'bold',
                          padding: 10,
                          height: 50,
                          width: !smDown && 300,
                          paddingRight: '10px',
                        }}
                      >
                        Please add a classroom
                      </Button>
                    ) : (!slots?.asap || selectedValue !== 'a') &&
                      !dateOfOrder ? (
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: 'lightgray',
                          color: 'black',
                          fontSize: 12,
                          fontWeight: 'bold',
                          padding: 10,
                          height: 50,
                          width: !smDown && 300,
                          paddingRight: '10px',
                        }}
                      >
                        {t('confirmPage.pleaseChooseTime')}
                      </Button>
                    ) : (
                      <div>
             {activeorders.length >= 10 ? (
                          <Button
                            disabled={true}
                            style={{
                              backgroundColor: 'lightgray',
                              color: 'black',
                              fontSize: 12,
                              fontWeight: 'bold',
                              padding: 10,
                              height: 50,
                              width: !smDown && 300,
                              paddingRight: '10px',
                            }}
                          >
                            {t('confirmPage.activeOrder')}
                          </Button>
                        ) : (
                          <CustomButton
                            buttonText={
                              // env?.paymentFlow == 'stripe'
                              // ?
                              `Place Your Order`
                              // : 'verify order'
                            }
                            onClick={() => setConfirmDialog(true)}
                            bg={environment.mainColor}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              ) : (
                <>
                  {' '}
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '13px',
                        marginBottom: '13px',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: environment.mainColor,
                          width: 250,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: 20,
                          height: 40,
                          padding: 10,
                          borderRadius: 10,
                        }}
                      >
                        <p style={{ marginRight: 10 }}>
                          {t('confirmPage.please')}
                        </p>
                        <Snipper color={'white'} />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '5px',
                      }}
                    >
                      {activeorders.length >= 10 ? (
                        <Button
                          disabled={true}
                          style={{
                            backgroundColor: 'lightgray',
                            color: 'black',
                            fontSize: 12,
                            fontWeight: 'bold',
                            padding: 10,
                            height: 50,
                            width: !smDown && 300,
                            paddingRight: '10px',
                          }}
                        >
                          {t('confirmPage.activeOrder')}
                        </Button>
                      ) : unavailableNow ? (
                        <Button
                          disabled={true}
                          style={{
                            backgroundColor: 'lightgray',
                            color: 'black',
                            fontSize: 12,
                            fontWeight: 'bold',
                            padding: 10,
                            height: 50,
                            width: !smDown && 300,
                            paddingRight: '10px',
                          }}
                        >
                          {t('confirmPage.err')}
                        </Button>
                      ) : (
                        <div style={{ marginTop: '10px' }}>
                          <CustomButton
                            buttonText={
                              // env?.paymentFlow == 'stripe'
                              //   ?
                              `${t('confirmPage.placeOrder')}`
                              // : 'verify order'
                            }
                            onClick={() => onConfirm()}
                            bg={environment.mainColor}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {/*  */}
          {openSucess && <SuccessDialog open={openSucess} setOpenSuccess={ setOpenSuccess} />}
          {orderType != 'dinein' ? (
            <>
              {/* {!unavailableNow && ( */}
              <NewPaymet
                open={
                  // env?.paymentFlow === 'stripe'
                  // ?
                  change === 'newCard' && openCredit
                  // : openCredit
                }
                cards={cards}
                setCards={setCards}
                setOpen={setOpenCredit}
                handleChange={setChange}
                onSelect={onSelect}
                change={change}
                paymentFlow={env?.paymentFlow}
                onConfirmClover={onConfirmClover}
              />
              {/* )} */}
            </>
          ) : (
            <NewPaymet
              open={
                // env?.paymentFlow === 'stripe'
                // ?
                change === 'newCard' && openCredit
                // : openCredit
              }
              cards={cards}
              setCards={setCards}
              setOpen={setOpenCredit}
              handleChange={setChange}
              onSelect={onSelect}
              change={change}
              paymentFlow={env?.paymentFlow}
              onConfirmClover={onConfirmClover}
            />
          )}
        </Container>
      </div>

      {openStripeDailog && (
        <Dialog
          open={openStripeDailog}
          onClose={handleCloseDailog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              textAlign: 'center',
              alignItems: 'center',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
          <ErrorIcon color="error" style={{ fontSize: '4em', display: 'block', margin: 'auto' }} />
          {t('confirmPage.transactionFailed')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {t('confirmPage.sorryTransactions')}
         
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
            {t('confirmPage.checkCard')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDailog} color="primary">
            {t('confirmPage.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmOrderPage;
