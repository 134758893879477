import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../../../../libs/components/src';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { setCart, setUser } from '../../../../../../libs/redux-slices/src';
import { environment } from '../../../environments/environment';

function HomeCardTQ(props) {
  const { name, description, item_image_url, id, price } = props.item;
  const token = useSelector((state) => state.authReducer.token);
  const [added, setAdded] = useState(false);
  const [extraQuantity, setExtraQuantity] = useState({});
  const dispatch = useDispatch();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const [totalPrice, setTotalPrice] = useState(props?.item?.price);
  const [defaultOption, setDefaultOption] = useState(null);
  const check = window.localStorage.getItem('items');
  const user = useSelector((state) => state.authReducer.userData);
  const menuDocs = useSelector((state) => state.authReducer.menuDocs);


  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'checkout':
        return check != 'true' ? licenses.frontend.checkout : false; default:
        return false;
    }
  };
  const [t, i18n] = useTranslation();

  const onAddToCart = async (item) => {
    try {
      if (token) {
        const bagitems = user?.bagItems;
        const newOption = {
          price: item.price,
          calculatedPrice: totalPrice,
          image: item.item_image_url,
          itemId: item.id,
          specialInstructions: '',
          title: item.name,
          quantity: 1,
          customization: extraQuantity,
        };
        const items = [...bagitems, newOption];
        const updateDoc = await firestore.collection('restaurant').doc();
        await firestore.collection('users').doc(token).set(
          {
            bagItems: items,
          },
          { merge: true }
        );

        await firestore
          .collection('restaurant')
          .doc('Restaurant')
          .set(
            {
              update_items_id: updateDoc.id,
            },
            { merge: true }
          )
          .then(() => {
            dispatch(setCart(items));
            setAdded(true);
            setTimeout(() => {
              setAdded(false);
            }, 2000);
          });
      } else if (!token) {
        navigate('signup');
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const getBagItems = async () => {

    const user = useSelector((state) => state.authReducer.userData);
    const bags = user?.bagItems;
    return bags;

  };

  const getPrice = () => {
    props?.modifiers?.map((m) => {
      if (props.item?.modifier_group_ids?.includes(m?.id)) {
        const defaultPrice = m?.minPermitted != 0 && m?.options.find((o) => o?.isDefault === true)?.price >= 0 ? m?.options.find((o) => o?.isDefault === true)?.price : 0;
        const defaultOption = m?.options.find((o) => o?.isDefault === true);
        //here we are getting the default price and adding it to the total because it's selected by default.
        setDefaultOption(defaultOption);
        setTotalPrice(totalPrice + defaultPrice);
      }
    })
  }

  useEffect(() => {
    getPrice();
  }, [totalPrice])
  const navigate = useNavigate();
  return (
    <div
      style={{ marginTop: '50px', cursor: 'pointer', marginBottom: '30px' }}

    >
      <div
        class="rounded-lg shadow-lg bg-white max-w-sm"
        style={{
          height: '27rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            class="rounded-t-lg"
            loading="lazy"
            src={item_image_url}
            onClick={() => {
              if (menuDocs?.length === 0) {
                navigate(`/menu`);
              } else {
                navigate(
                  `/menu-items`
                );
              }
            }}

            alt={name}
            style={{
              borderRadius: '100%',
              width: '50%',
              height: '180px',
              marginTop: '20px',
            }}
          />
        </div>
        <div
          class="p-1"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          <h3
            class="text-gray-900 text-xl font-medium mb-2 bold"
            style={{ fontWeight: 'bold' }}
          >
            {name}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            color: 'lightgray'
          }}
        >
          <p class="text-gray-700 text-base mb-4" style={
            { padding: '3px' }
          }>
            {' '}
            {description.length > 80 ? description.slice(0, 80) : description}
            {description.length > 80 ? '...' : ''}
          </p>
        </div>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            color: environment.mainColor,
            fontWeight:'bold'
          }}
        >
          ${totalPrice}
        </div> */}

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          {checkLicenses('checkout') ? (
            <>
              {' '}
              {/* {added ? (
                <CustomButton
                  bg={environment.mainColor}
                  buttonText={'Added'}
                  onClick={() => onAddToCart(props.item)}
                  admin={false}
                />
              ) : (
                <CustomButton
                  bg={environment.mainColor}
                  buttonText={'Add to cart'}
                  onClick={() => onAddToCart(props.item)}
                  admin={false}
                />
              )} */}
              <CustomButton
                bg={environment.mainColor}
                buttonText={t('orderNow')}
                onClick={() => navigate(`/menu/${props?.item?.id}`)}

                admin={false}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
export default HomeCardTQ;
