import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
export const fetchData = async (url, method, body, customHeaders = {}) => {
  let axiosConfig = {
    method: method,
    url: url,
    data: body,
    headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
  };

  const result = await axios(axiosConfig);
  return result;
};


export const handleToaster = (status, phrase) => {
    const toasterConfig = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: false,
    };

    if (status === 'success') {
      toast.success(`${phrase}`, toasterConfig);
    } else if (status === 'error') {
      toast.error(`${phrase}!`, toasterConfig);
    }
  };
