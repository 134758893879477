import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  CardMedia,
  useMediaQuery,
  DialogContentText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { firestore } from '@tabletuck/firebase-services';
import moment from 'moment';
import ChangeItem from './ChangeItem';
import { useDispatch, useSelector } from 'react-redux';
import OnePermitted from './OnePermitted';
import { environment } from '../../../environments/environment';
import {
  checkStore,
  setCart,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import Snipper from 'libs/components/src/lib/snipper/Snipper';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useNavigate } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Carousel } from 'react-responsive-carousel';
// import './Home.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ChildItemModifier from './ChildItemModifier';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { handleToaster } from '../../Utils/UtilsFunctions';
import { ToastContainer } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomizeDialoge({
  open,
  handleClose,
  c,
  data,
  defs,
  defaultPrices,
  priceToShow,
  extraQuantity,
  setExtraQuantity,
  outofStockDelivery,
  openn,
  handleClosee,
  handleOpenn,
  enableAdd,
}) {
  const [t, i18n] = useTranslation();

  const [state, setState] = React.useState({});
  const [calculatedPrice, setCalculatedPrice] = useState(priceToShow);
  const [showResults, setShowResults] = React.useState(true);
  const [err, setErr] = useState(false);
  const [optionsPrice, setOptionsPrice] = useState(0);
  const [optionsPriceChild, setOptionsPriceChild] = useState(0);
  const [optionsPriceChildCheckbox, setOptionsPriceChildCheckbox] = useState(0);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const checkStoree = useSelector((state) => state.authReducer.checkStore);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [checkedOptions, setCheckedOptions] = useState([]);
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const [checkedModifiers, setCheckedModifiers] = useState([]);
  const [favorites, setfav] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [zeros, setZeros] = useState({});
  const [reqError, setReqError] = useState('');
  const [errReq, setErrReq] = useState(false);
  const navigate = useNavigate();
  const [added, setAdded] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPrice, setCurrrentPrice] = useState(c.price);
  const [defaultPrice, setDefaultPrice] = useState(0);
  const [option, setOption] = useState([]);
  const [maxErr, setMaxErr] = useState(false);
  const [sumTotal, setSum] = useState(1);
  const [sumOp, setSumOp] = useState([]);
  const [summErr, setSummErr] = useState(false);
  const [defaultOption, setDefaultOption] = useState({});
  const [errors, setErrors] = useState({});
  const [checkStock, setCheckStock] = useState('');
  const [outofStockDineIn, setOutOfStockDineIn] = useState('');
  const [checkStockDelivery, setCheckStockDelivery] = useState('');
  const [storeId, setStoreId] = useState('');
  const [outofStockTimeDineIn, setOutofStockTimeDineIn] = useState(null);
  const [outofStockTimeDelivery, setOutofStockTimeDelivery] = useState(null);
  const user = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const [showChildoneP, setShowChildP] = useState(false);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const [childModifiersPer, setChildModifiersPer] = useState([]);
  const [childPriceOption, setChildPriceOption] = useState(0);
  const [modid, setModid] = useState('');
  const [optionid, setOptionId] = useState('');
  const [optionPriceChild, setOptionPriceChild] = useState(0);
  const theme = useTheme();
  const cart = useSelector((state) => state.shopReducer.cart);

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    console.log(extraQuantity);
  }, [
    modid,
    extraQuantity,
    optionid,
    calculatedPrice,
    defaultPrice,
    childPriceOption,
  ]);
  let date = new Date();
  const getUser = async () => {
    setfav(user?.favorites);
  };
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'checkout':
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    getUser();
  }, [auth?.currentUser?.uid, favorites]);

  const checkoutOfstockDelivery = () => {
    if (outofStockDelivery && outofStockDelivery?.length === 0) {
      setCheckStockDelivery('');
    } else
      outofStockDelivery &&
        outofStockDelivery.filter((d) => {
          if (
            d?.id === c?.id &&
            user &&
            user?.pickupOrDelivery === 'delivery'
          ) {
            setCheckStockDelivery(`${t('menu.thisItemIsCurrentlyOutOfStock')}`);
            setOutofStockTimeDelivery(
              new Date(d?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStockDelivery('');
        });
  };
  useEffect(() => {
    checkoutOfstockDelivery();
  }, []);
  const getStorePickupDinein = () => {
    stores?.filter((store) => {
      if (store?.id === user?.defaultStore) {
        setOutOfStockDineIn(store?.out_of_stock);
      }
    });
  };
  const outofStock = async () => {
    if (outofStockDineIn && outofStockDineIn?.length === 0) {
      setCheckStock('');
    } else
      outofStockDineIn &&
        outofStockDineIn.filter((d) => {
          if (d.id === c.id && user?.pickupOrDelivery != 'delivery') {
            setCheckStock(`${t('menu.thisItemIsCurrentlyOutOfStock')}`);
            setOutofStockTimeDineIn(
              new Date(d?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStock('');
        });
  };

  useEffect(() => {
    getStorePickupDinein();
    outofStock();
  }, [favorites, user]);

  useEffect(() => {
    if (user?.uid) {
      getStorePickupDinein();
    }
  }, [user, stores]);
  useEffect(() => {
    //this fix works when we have one default option only, depending on the code
    //I didn't see that we have more.
    data?.length > 0 &&
      data?.map((d, index) => {
        if (c?.modifier_group_ids?.includes(d?.id)) {
          const defaultPriceO =
            d?.minPermitted != 1 &&
            d?.options.find((o) => o?.isDefault === true)?.price >= 0
              ? d?.options.find((o) => o?.isDefault === true)?.price
              : 0;
          const defaultOption = d?.options.find((o) => o?.isDefault === true);
          //here we are getting the default price and adding it to the total because it's selected by default.
          setDefaultPrice(1);
          //we set the extra quantity to the dafault, so it's saved to firebase with the option, not only the price increased.
          if (defaultOption) {
            setExtraQuantity((exq) => ({
              ...exq,
              [d?.id]: [
                {
                  customizationMeta: {},
                  defaultQuantity: defaultOption?.minPermitted,
                  id: defaultOption?.id,
                  modifierTitle: d?.title,
                  price: defaultOption?.price,
                  quantity: 1,
                  title: defaultOption?.title,
                },
              ],
            }));
          }
        }
      });
  }, [c]);
  useEffect(() => {
    setExtraQuantity({});
  }, []);

  const addFavorite = async () => {
    try {
      let arr = user?.favorites ? user?.favorites : [];
      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: [...arr, c?.id],
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, favorites: [...arr, c?.id] };
          dispatch(setUser({ userData: updatedUser }));
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const removeFavoriteTasker = async (taskerID) => {
    // setUser(user);

    try {
      let arr = user?.favorites;

      arr = arr.filter(function (item) {
        return item !== c?.id;
      });

      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: arr,
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, favorites: arr };
          dispatch(setUser({ userData: updatedUser }));

          // dispatch(setUser({ user: updatedUser }));
        });
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (data?.length > 0) {
      data?.map((d) => {
        if (c?.modifier_group_ids.includes(d?.id)) {
          if (
            checkedOptions?.length === 0 &&
            d?.minPermitted >= 1 &&
            d?.maxPermittedUnique != 1
          ) {
            setErrReq(`${t('menu.plzChooseOne')}`);
          } else {
            setErrReq('');
          }
        }
      });
    }
  }, [checkedOptions, data, c?.id]);
  useEffect(() => {
    setCalculatedPrice(priceToShow);
  }, [priceToShow]);

  useEffect(() => {
    setDefaultPrice(defaultPrices);
    // setDefaultPrices(defaultPrices);
  }, [defaultPrices]);

  const handleChangeChild = (
    event,
    modifier,
    setValue,
    setPrev,
    prev,
    value,
    q,
    price
  ) => {
    setErrReq(false);
    //here we are removing the default price and set it to 0 so the number doesn't keep decreasing.

    //here we are removing the default price and set it to 0 so the number doesn't keep decreasing.
    setCalculatedPrice(calculatedPrice - defaultPrice);
    setDefaultPrice(0);
    setValue(event?.target?.value);

    // if(event.target.checked){
    const option = modifier?.options?.filter(
      (option) => option?.title == event.target.value
    )[0];
    if (prev !== null) {
      setCalculatedPrice(
        (old) => old + option.price * quantity - prev * quantity
      );
    } else {
      setCalculatedPrice((old) => old + option.price * quantity);
    }
    setCurrrentPrice((old) => old - prev + option.price);
    setPrev(option.price);
    setChildPriceOption(option.price);
    // setDefaultPrice(option?.price);
    // setCurrrentPrice((old) => old - prev + option.price);
    const compositeKey = `${modid},${optionid},${modifier?.id}`;
    setExtraQuantity((exq) => ({
      ...exq,
      [compositeKey]: [
        {
          customizationMeta: {},
          defaultQuantity: option?.minPermitted,
          id: option?.id,
          modifierTitle: option?.title,
          price: option?.price,
          quantity: 1,
          title: modifier?.title,
        },
      ],
    }));
  };

  const handleChange = (
    event,
    modifier,
    setValue,
    setPrev,
    prev,
    value,
    q,
    price
  ) => {
    setErrReq(false);
    setChildModifiersPer([]);
    setErrReq('');
    setOptionsPriceChildCheckbox(0);
    setSummErr('');
    //here we are removing the default price and set it to 0 so the number doesn't keep decreasing.
    if (modifier?.maxPermitted > modifier?.maxPermittedUnique && q) {
      if (!q) {
        setCalculatedPrice(calculatedPrice - prev ? prev : 0);
      } else {
        setCalculatedPrice(c?.price + defaultPrice);
      }
      setDefaultPrice(0);
      setValue(
        event?.target?.value === undefined ? event : event?.target?.value
      );
      const optionName =
        event?.target?.value === undefined ? event : event?.target?.value;
      // if(event.target.checked){
      const option = modifier?.options?.filter(
        (option) => option?.title == optionName
      )[0];

      if (prev != null && price) {
        //setOptionsPrice(0);
        setCalculatedPrice(c?.price + price);
      }
      if (prev == null && price) {
        // setOptionsPrice(0);
        setCalculatedPrice(c?.price + price);
      }
      setCurrrentPrice((old) => old - prev + option.price);
      setPrev(option.price);
      if (option?.childModifiers.length === 0) {
        setExtraQuantity((exq) => ({
          // ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: option?.minPermitted,
              id: option?.id,
              modifierTitle: option?.title,
              price: option?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
      } else if (option?.childModifiers.length > 0) {
        console.log('in childmod in change2');
        setExtraQuantity((exq) => ({
          ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: option?.minPermitted,
              id: option?.id,
              modifierTitle: option?.title,
              price: option?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
        setModid(modifier?.id);
        setOptionId(option?.id);
      }
      // setExtraQuantity((exq) => ({
      //   ...exq,
      //   [modifier?.id]: [
      //     {
      //       customizationMeta: {},
      //       defaultQuantity: option?.minPermitted,
      //       id: option?.id,
      //       modifierTitle: option?.title,
      //       price: option?.price,
      //       quantity:
      //         parseInt(Object.values(q).toString()) >= 1
      //           ? parseInt(Object.values(q).toString())
      //           : 1,
      //       title: modifier?.title,
      //     },
      //   ],
      // }));
    } else {
      //here we are removing the default price and set it to 0 so the number doesn't keep decreasing.
      setCalculatedPrice(
        calculatedPrice -
          defaultPrice -
          childPriceOption -
          optionsPriceChildCheckbox
      );
      setOptionsPriceChildCheckbox(0);
      setDefaultPrice(0);
      setValue(event?.target?.value);
      // if(event.target.checked){
      const option = modifier?.options?.filter(
        (option) => option?.title == event?.target?.value
      )[0];
      if (prev !== null) {
        setCalculatedPrice(
          (old) => old + option.price * quantity - prev * quantity
        );
      } else {
        setCalculatedPrice((old) => old + option.price * quantity);
      }
      setCurrrentPrice((old) => old - prev + option.price);
      setPrev(option.price);
      console.log(option?.childModifiers, 'lllll');
      if (option?.childModifiers.length === 0) {
        setExtraQuantity((exq) => ({
          // ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: option?.minPermitted,
              id: option?.id,
              modifierTitle: option?.title,
              price: option?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
      } else if (option?.childModifiers.length > 0) {
        setExtraQuantity((exq) => ({
          ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: option?.minPermitted,
              id: option?.id,
              modifierTitle: option?.title,
              price: option?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
        setModid(modifier?.id);
        setOptionId(option?.id);
      }

      setChildPriceOption(0);
    }
  };
  const getBagItems = () => {
    try {
      const bags = user?.bagItems;
      return bags;
    } catch (e) {
      console.log('catch1:', e.message);
    }
  };

  const checkSumAdd = (sum, id, mo) => {
    setSummErr('');
    let newTaskObj = sumOp;
    newTaskObj[id] = sum[id];
    //sumOp is the total sum of options that we modify to take. the latest value
    setSumOp(sumOp);
    let s = 0;
    Object.values(sumOp).forEach((num) => (s = s + num));
    let err = mo?.maxPermitted >= s ? false : true;
    setSummErr(err);
    if (checkedOptions?.length > 0 && !err) {
      //sumTotal is the total number of options
      setSum(s);
    }
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const checkSumM = (sum, id, mo) => {
    setSummErr('');
    // setModifiers(updatedModifiers);
    const isSelected = selectedOptions.includes(id);
    let updatedSelectedOptions;
    if (isSelected) {
      updatedSelectedOptions = selectedOptions.filter((id) => id !== id);
    } else {
      const currentModifier = data.find((modifier) =>
        modifier.options.some((option) => option.id === id)
      );
      if (
        selectedOptions.filter((id) =>
          data
            .find((modifier) => modifier.id === currentModifier.id)
            .options.map((option) => option.id)
            .includes(id)
        ).length < currentModifier.maxPermittedUnique
      ) {
        updatedSelectedOptions = [...selectedOptions, id];
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [currentModifier.id]: `Maximum permitted options exceeded for ${currentModifier.title}.`,
        }));
        return;
      }
    }
    setSelectedOptions(updatedSelectedOptions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: undefined,
    }));
  };
  const checkBoxchangeChild = (
    e,
    state,
    setState,
    option,
    modifier,
    v,
    setValue,
    setMax,
    max
  ) => {
    setOptionsPriceChildCheckbox(0);
    if (e?.target?.checked) {
      console.log(option);
      setMax((v) => v + 1);
      if (max + 1 > modifier?.maxPermittedUnique) {
        setZeros((z) => ({
          ...z,
          [modifier?.title]: true,
        }));
        setErr(true);
      }
      let c = optionsPriceChildCheckbox + option?.price;
      setOptionsPriceChildCheckbox(option?.price);
      setState((state) => ({ ...state, [e?.target?.name]: true }));
      // setCalculatedPrice( calculatedPrice + c);

      const compositeKey = `${modid},${optionid},${modifier?.id}`;
      setExtraQuantity((exq) => ({
        ...exq,
        [compositeKey]: [
          {
            customizationMeta: {},
            defaultQuantity: option?.minPermitted,
            id: option?.id,
            modifierTitle: option?.title,
            price: option?.price,
            quantity: 1,
            title: modifier?.title,
          },
        ],
      }));
    } else {
      setErr(false);
      setZeros((z) => ({
        ...z,
        [modifier?.title]: false,
      }));
      setOptionsPriceChildCheckbox((old) =>
        old - option.price * v > 0 ? old - option?.price * v : 0
      );
      // setCalculatedPrice( calculatedPrice - optionsPriceChildCheckbox);

      // setOptionsPrice((old) => (option?.price * value) -  old);
      setState((state) => ({ ...state, [e?.target?.name]: false }));
      setValue(1);
      setMax((v) => v - 1);

      const c = { ...extraQuantity };

      c[modifier?.id] = c[modifier?.id]?.filter((x) => x?.id !== option?.id);
      setExtraQuantity(c);
    }
  };
  useEffect(() => {
    console.log(optionsPriceChildCheckbox);
  }, [optionsPriceChildCheckbox]);
  const checkBoxchange = (
    e,
    state,
    setState,
    option,
    modifier,
    v,
    setValue,
    setMax,
    max
  ) => {
    if (e?.target?.checked) {
      setMax((v) => v + 1);
      if (max + 1 > modifier?.maxPermittedUnique) {
        setZeros((z) => ({
          ...z,
          [modifier?.title]: true,
        }));
        setErr(true);
      }
      setOptionsPrice(optionsPrice + option?.price);
      setState((state) => ({ ...state, [e?.target?.name]: true }));
      console.log(option?.childModifiers);
      if (option?.childModifiers.length === 0) {
        setExtraQuantity((exq) => ({
          ...exq,
          [modifier?.id]: !exq[modifier?.id]
            ? [
                {
                  // ...exq[modifier.title],
                  customizationMeta: {},
                  defaultQuantity: option?.minPermitted,
                  id: option?.id,
                  modifierTitle: option?.title,
                  price: option?.price,
                  quantity: v,
                  title: modifier?.title,
                },
              ]
            : [
                ...exq[modifier?.id],
                {
                  // ...exq[modifier.title],
                  customizationMeta: {},
                  defaultQuantity: option?.minPermitted,
                  id: option?.id,
                  modifierTitle: option?.title,
                  price: option?.price,
                  quantity: v,
                  title: modifier?.title,
                },
              ],
        }));
      } else if (option?.childModifiers.length > 0) {
        setExtraQuantity((exq) => ({
          ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: option?.minPermitted,
              id: option?.id,
              modifierTitle: option?.title,
              price: option?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
        setModid(modifier?.id);
        setOptionId(option?.id);
      }
    } else {
      setErr(false);
      setZeros((z) => ({
        ...z,
        [modifier?.title]: false,
      }));
      setOptionsPrice((old) =>
        old - option.price * v > 0 ? old - option?.price * v : 0
      );
      // setOptionsPrice((old) => (option?.price * value) -  old);
      setState((state) => ({ ...state, [e?.target?.name]: false }));
      setValue(1);
      setMax((v) => v - 1);

      const c = { ...extraQuantity };

      c[modifier?.id] = c[modifier?.id]?.filter((x) => x?.id !== option?.id);
      setExtraQuantity(c);

      console.log(modifier, option, 'djgkdsfhkdsfhfkhkfhdksfhfdh');
      if (option?.childModifiers.length > 0) {
        setCalculatedPrice(calculatedPrice - childPriceOption);
        setOptionsPriceChildCheckbox(0);
        setChildPriceOption(0);
      }
    }
  };

  const checkCart = async () => {
    if (checkStoree === true) {
      firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            bagItems: [],
          },
          { merge: true }
        )
        .then(() => {
          console.log('updated');
          dispatch(setCart([]));
          const updatedUser = { ...user, bagItems: [] };
          dispatch(setUser({ userData: updatedUser }));
        })
        .catch((e) => {
          console.log(e);
        });
      dispatch(checkStore(false));
    }
  };
  useEffect(() => {
    console.log(user?.bagItems, cart, 'test with');
  }, [user?.bagItems, cart]);
  const confirmDelete = async () => {
    checkCart();
    const newErrors = {};

    data?.forEach((modifier) => {
      const selectedOptionsForModifier = selectedOptions.filter((id) =>
        modifier.options.map((option) => option.id).includes(id)
      );

      // if (selectedOptionsForModifier.length < modifier.minPermitted) {
      //   newErrors[modifier.id] = `Minimum permitted options not selected for ${modifier.title}.`;
      // } else
      if (selectedOptionsForModifier.length > modifier.maxPermittedUnique) {
        newErrors[
          modifier.id
        ] = `Maximum permitted options exceeded for ${modifier.title}.`;
      }
    });

    setErrors(newErrors);

    if (!summErr) {
      setErrReq('');
      let m;
      data?.map((d) => {
        if (c?.modifier_group_ids.includes(d?.id)) {
          if (
            checkedOptions?.length === 0 &&
            d?.minPermitted != 1 &&
            d?.maxPermittedUnique != 1
          ) {
            // setErrReq("Please choose one if the Options.");
            m = true;
          } else {
            m = false;
          }
        }
      });

      // if (!m) {
      if (zeros) {
        for (let x in zeros) {
          if (zeros[x] === true) {
            setReqError(`${t('menu.plzDontCrossItems')}`);
            return;
          }
        }
      }
      setLoading(true);
      setReqError('');

      try {
        console.log(user?.bagItems, user, 'skkck');
        const bagitems = [];
        const newOption = {
          price: c?.price,
          calculatedPrice:
            calculatedPrice + optionsPrice + optionsPriceChildCheckbox,
          image: c?.item_image_url,
          itemId: c?.id,
          specialInstructions: '',
          title: c?.name,
          quantity: quantity,
          customization: extraQuantity,
          dinein_enabled: c?.dinein_enabled,
          delivery_enabled: c?.delivery_enabled,
          pickup_enabled: c?.pickup_enabled,
        };
        // new option customization ids:
        const optionKeys = Object.keys(newOption?.customization);
        let optionIds = [];
        optionKeys.forEach((key) => {
          const innerArray = newOption?.customization[key];
          const ids = innerArray?.map((item) => item?.id);

          optionIds = [...optionIds, ...ids];
        });
        // End
        let items = [];
        let itemUpdated = false;
        let elementIds = [];

        console.log(itemUpdated, 'itemUpdated');
        if (!itemUpdated) {
          items.push(newOption);
        }

        dispatch(setCart(items));
        // const updateDoc = await firestore.collection('restaurant').doc();
        await firestore
          .collection('users')
          .doc(auth?.currentUser?.uid)
          .set(
            {
              bagItems: items,
            },
            { merge: true }
          )
          .then(() => {
            console.log('updated');
            dispatch(setCart(items));
            const updatedUser = { ...user, bagItems: items };
            dispatch(setUser({ userData: updatedUser }));
            elementIds = [];
          })
          .catch((e) => {
            console.log(e);
          });

        // await firestore.collection('restaurant').doc('Restaurant').set(
        //   {
        //     update_items_id: updateDoc?.id,
        //   },
        //   { merge: true }
        // );

        setLoading(false);
        setAdded(true);
        setTimeout(() => {
          setAdded(false);
        }, 2000);
        setReqError('');
        dispatch(setCart(items));

        const updatedUser = { ...user, bagItems: items };
        dispatch(setUser({ userData: updatedUser }));
      } catch (e) {
        console.log('catch2:', e.message);
      }
      // }
      //  else {
      //   setErrReq('Please choose one of the Options.');
      // }
    }
    setDeleteDialogOpen(false);
  };

  const handleAddToCart = async () => {


    if (checkStoree === true) {
      setDeleteDialogOpen(true)
      
  } else {
    const newErrors = {};

    data?.forEach((modifier) => {
      const selectedOptionsForModifier = selectedOptions?.filter((id) =>
        modifier?.options.map((option) => option.id)?.includes(id)
      );

      if (selectedOptionsForModifier?.length > modifier?.maxPermittedUnique) {
        newErrors[
          modifier?.id
        ] = `Maximum permitted options exceeded for ${modifier?.title}.`;
      }
    });

    setErrors(newErrors);

    if (!summErr) {
      setErrReq('');
      let m;
      data?.map((d) => {
        if (c?.modifier_group_ids.includes(d?.id)) {
          if (
            checkedOptions?.length === 0 &&
            d?.minPermitted != 1 &&
            d?.maxPermittedUnique != 1
          ) {
            m = true;
          } else {
            m = false;
          }
        }
      });

      if (zeros) {
        for (let x in zeros) {
          if (zeros[x] === true) {
            setReqError(`${t('menu.plzDontCrossItems')}`);
            return;
          }
        }
      }
      setLoading(true);
      setReqError('');

      try {
        const bagitems = user?.bagItems;
        const newOption = {
          price: c?.price,
          calculatedPrice:
            calculatedPrice + optionsPrice + optionsPriceChildCheckbox,
          image: c?.item_image_url,
          itemId: c?.id,
          specialInstructions: '',
          title: c?.name,
          quantity: quantity,
          customization: extraQuantity,
          dinein_enabled: c?.dinein_enabled,
          delivery_enabled: c?.delivery_enabled,
          pickup_enabled: c?.pickup_enabled,
          isCatering: c?.isCatering ?? false,
        };
        const optionKeys = Object.keys(newOption?.customization);
        let optionIds = [];
        optionKeys.forEach((key) => {
          const innerArray = newOption?.customization[key];
          const ids = innerArray?.map((item) => item?.id);

          optionIds = [...optionIds, ...ids];
        });
        let items = [];
        let itemUpdated = false;
        let elementIds = [];
        if (bagitems?.length > 0) {
          items = bagitems.map((element) => {
            const elementKeys = Object.keys(element?.customization);

            elementKeys.forEach((key) => {
              const innerArray = element?.customization[key];
              const ids = innerArray?.map((item) => item?.id);
              elementIds = ids;
            });

            if (
              element.itemId === newOption.itemId &&
              JSON.stringify(elementIds) === JSON.stringify(optionIds)
            ) {
              itemUpdated = true;

              return {
                ...element,
                quantity: element.quantity + 1,
                calculatedPrice:
                  newOption.calculatedPrice * (element.quantity + 1),
              };
            } else {
              return element;
            }
          });
        }
        console.log(itemUpdated, 'itemUpdated');
        if (!itemUpdated) {
          items.push(newOption);
        }

        dispatch(setCart(items));

        // const updateDoc = await firestore.collection('restaurant').doc();
        if (auth?.currentUser?.uid) {
          await firestore
            .collection('users')
            .doc(auth.currentUser.uid)
            .set(
              {
                bagItems: items,
              },
              { merge: true }
            )
            .then(() => {
              console.log('Updated in Firestore');
              dispatch(setCart(items));
              const updatedUser = { ...user, bagItems: items };
              dispatch(setUser({ userData: updatedUser }));
              elementIds = [];
            })
            .catch((e) => {
              console.log('Error updating items:', e);
              handleToaster('error', 'error in add to cart');
              setLoading(false);
            });
        } else {
          // navigate('/signup');
          localStorage.setItem('cartItems', JSON.stringify(items));
          console.log('Updated in Local Storage');
        }

        setLoading(false);
        setAdded(true);
        setTimeout(() => {
          setAdded(false);
        }, 2000);
        setReqError('');
        dispatch(setCart(items));

        const updatedUser = { ...user, bagItems: items };
        dispatch(setUser({ userData: updatedUser }));
      } catch (e) {
        console.log('catch2:', e.message);
      }
    }
  }
  };

  const handleChooseModifires = (event, option, max, i, d) => {
    if (option.childModifiers !== '') {
      setShowResults(true);
    }
    if (option.childModifiers !== '') {
      state[event.target.name] = event.target.checked;
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
      let checked = [];
      if (checked.length > max) {
        setErr(true);
      } else {
        setErr(false);
      }
    }
    let a = [...checkedOptions];
    if (state[event.target.name]) {
      a = [...checkedOptions, option];
    }
    if (!state[event.target.name]) {
      a.splice(a.indexOf(option), 1);
    }
    setCheckedOptions([...a]);
    //[o.id]: d is the modifier data for this option.
    let m = { ...checkedModifiers, [option?.id]: d };
    setCheckedModifiers(m);
  };
  const handleChooseModifiresChild = (event, option, max, i, d) => {
    if (option.childModifiers !== '') {
      setShowResults(true);
    }
    if (option.childModifiers !== '') {
      state[event.target.name] = event.target.checked;
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
      let checked = [];
      if (checked.length > max) {
        setErr(true);
      } else {
        setErr(false);
      }
    }

    let a = [...checkedOptions];
    if (state[event.target.name]) {
      a = [...checkedOptions, option];
    }
    if (!state[event.target.name]) {
      a.splice(a.indexOf(option), 1);
    }

    setCheckedOptions([...a]);

    //[o.id]: d is the modifier data for this option.
    let m = { ...checkedModifiers, [option?.id]: d };
    setCheckedModifiers(m);
  };
  const handleChangeOption = (v) => {
    setOptionsPrice(v);
  };

  return (
    <>
      {/* <Header env={environment} isAdmin={false} /> */}

      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogContent>
          <DialogTitle>Start a new basket?</DialogTitle>
          <DialogContentText>
            A new order will clear your basket.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} style={{ color: 'grey' }}>
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            style={{ color: environment?.mainColor }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: '0px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'start',
            borderRadius: '25px',
            marginTop: '15px',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              color: 'black',
              cursor: 'pointer',
              marginLeft: '15px',
            }}
            onClick={() => {
              handleClose();
              setOptionsPrice(0);
              setDefaultPrice(0);
              setCalculatedPrice(c?.price);
            }}
          />
        </div>
        <DialogTitle
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {' '}
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {c.name}
          </Typography>
          {checkLicenses('checkout') && (
            <>
              {auth?.currentUser?.uid ? (
                <div style={{ marginBottom: '16px' }}>
                  {user?.favorites?.includes(c?.id) ? (
                    <FavoriteIcon
                      style={{
                        color: environment.mainColor,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        removeFavoriteTasker(c?.id);
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      style={{
                        color: environment.mainColor,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        addFavorite();
                      }}
                    />
                  )}
                </div>
              ) : (
                <FavoriteBorderIcon
                  style={{
                    color: environment.mainColor,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/signup');
                  }}
                />
              )}
            </>
          )}{' '}
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="body1"
            style={{
              color: 'gray',
              marginBottom: '20px',
            }}
          >
            {c.description}
          </Typography>

          <>
            <Carousel
              // width={'550px'}
              showArrows={true}
              infiniteLoop={true}
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              height={'400px'}
              showIndicators={false}
              verticalSwipe="standard"
            >
              {c?.listOfImages.map((cc) => (
                <CardMedia
                  style={{
                    height: smDown ? '270px' : 400,
                    width: smDown ? '270px' : '550px',
                  }}
                  image={cc}
                />
              ))}
            </Carousel>
          </>

          {/* <img
            src={c.item_image_url}
            alt="Item"
            className="dialog-item-image-dialog"
            style={{ width: '550px', height: '400px' }}
          /> */}

          <Grid>
            <Grid
              item
              xs={12}
              sx={{
                maxWidth: { xs: '100%', md: '100%', lg: '100%' },
                flexBasis: { xs: '100%', md: '100%', lg: '100%' },
              }}
            >
              {c &&
                c?.groups?.length > 0 &&
                c?.groups.map((d) => (
                  <div>
                    {d?.minPermitted > 0 && d?.maxPermittedUnique == 1 && (
                      <>
                        <OnePermitted
                          modifier={d}
                          showChildoneP={showChildoneP}
                          handleChange={handleChange}
                          setOptionsPrice={setOptionsPrice}
                          setExtraQuantity={setExtraQuantity}
                          setCalculatedPrice={setCalculatedPrice}
                          calculatedPrice={calculatedPrice}
                          priceToShow={priceToShow}
                          handleClose={handleClose}
                          setShowChildP={setShowChildP}
                          handleOpenn={handleOpenn}
                          openn={openn}
                          c={c}
                          handleClosee={handleClosee}
                          handleChangeChild={handleChangeChild}
                          setZeros={setZeros}
                          errReq={errReq}
                          optionsPriceChild={optionsPriceChild}
                          setOptionsPriceChild={setOptionsPriceChild}
                          checkSumM={checkSumM}
                          checkSumAdd={checkSumAdd}
                          setMaxErr={setMaxErr}
                          summErr={summErr}
                          checkedModifiers={checkedModifiers}
                          setCheckedModifiers={setCheckedModifiers}
                          checkedOptions={checkedOptions}
                          handleChooseModifires={handleChooseModifires}
                          checkBoxchangeChild={checkBoxchangeChild}
                        />
                      </>
                    )}
                    {d?.minPermitted == 0 && d?.maxPermittedUnique == 1 && (
                      <ChangeItem
                        id={d?.id}
                        zeros={zeros}
                        setZeros={setZeros}
                        modifiersGroup={data}
                        modifier={d}
                        showResults={showResults}
                        setExtraQuantity={setExtraQuantity}
                        extraQuantity={extraQuantity}
                        optionsPrice={optionsPrice}
                        handleChooseModifires={handleChooseModifires}
                        setOptionsPrice={setOptionsPrice}
                        checkedModifiers={checkedModifiers}
                        setCheckedModifiers={setCheckedModifiers}
                        checkedOptions={checkedOptions}
                        errReq={errReq}
                        checkSumAdd={checkSumAdd}
                        checkSumM={checkSumM}
                        setMaxErr={setMaxErr}
                        summErr={summErr}
                        handleChangeChild={handleChangeChild}
                        optionsPriceChild={optionsPriceChild}
                        checkBoxchange={checkBoxchange}
                        setOptionsPriceChild={setOptionsPriceChild}
                        handleChange={handleChange}
                        setCalculatedPrice={setCalculatedPrice}
                        calculatedPrice={calculatedPrice}
                        priceToShow={priceToShow}
                        handleClose={handleClose}
                        setShowChildP={setShowChildP}
                        handleOpenn={handleOpenn}
                        openn={openn}
                        c={c}
                        handleClosee={handleClosee}
                        checkBoxchangeChild={checkBoxchangeChild}
                      />
                    )}

                    {d?.minPermitted == 0 && d?.maxPermittedUnique != 1 && (
                      <ChangeItem
                        id={d?.id}
                        zeros={zeros}
                        setZeros={setZeros}
                        modifiersGroup={data}
                        modifier={d}
                        showResults={showResults}
                        setExtraQuantity={setExtraQuantity}
                        extraQuantity={extraQuantity}
                        optionsPrice={optionsPrice}
                        handleChooseModifires={handleChooseModifires}
                        setOptionsPrice={setOptionsPrice}
                        checkedModifiers={checkedModifiers}
                        setCheckedModifiers={setCheckedModifiers}
                        checkedOptions={checkedOptions}
                        errReq={errReq}
                        checkSumAdd={checkSumAdd}
                        checkSumM={checkSumM}
                        errors={errors[d.id]}
                        setMaxErr={setMaxErr}
                        summErr={summErr}
                        handleChangeChild={handleChangeChild}
                        optionsPriceChild={optionsPriceChild}
                        setOptionsPriceChild={setOptionsPriceChild}
                        checkBoxchange={checkBoxchange}
                        handleChange={handleChange}
                        setCalculatedPrice={setCalculatedPrice}
                        calculatedPrice={calculatedPrice}
                        priceToShow={priceToShow}
                        handleClose={handleClose}
                        setShowChildP={setShowChildP}
                        handleOpenn={handleOpenn}
                        openn={openn}
                        c={c}
                        handleClosee={handleClosee}
                        checkBoxchangeChild={checkBoxchangeChild}
                      />
                    )}

                    {d?.minPermitted > 0 && d?.maxPermittedUnique != 1 && (
                      <ChangeItem
                        id={d?.id}
                        zeros={zeros}
                        setZeros={setZeros}
                        modifiersGroup={data}
                        modifier={d}
                        showResults={showResults}
                        setExtraQuantity={setExtraQuantity}
                        extraQuantity={extraQuantity}
                        optionsPrice={optionsPrice}
                        handleChooseModifires={handleChooseModifires}
                        setOptionsPrice={setOptionsPrice}
                        checkedModifiers={checkedModifiers}
                        setCheckedModifiers={setCheckedModifiers}
                        checkedOptions={checkedOptions}
                        errReq={errReq}
                        summErr={summErr}
                        checkSumAdd={checkSumAdd}
                        checkSumM={checkSumM}
                        setMaxErr={setMaxErr}
                        errors={errors[d.id]}
                        handleChangeChild={handleChangeChild}
                        optionsPriceChild={optionsPriceChild}
                        setOptionsPriceChild={setOptionsPriceChild}
                        checkBoxchange={checkBoxchange}
                        handleChange={handleChange}
                        setCalculatedPrice={setCalculatedPrice}
                        calculatedPrice={calculatedPrice}
                        priceToShow={priceToShow}
                        handleClose={handleClose}
                        setShowChildP={setShowChildP}
                        handleOpenn={handleOpenn}
                        openn={openn}
                        c={c}
                        handleClosee={handleClosee}
                        checkBoxchangeChild={checkBoxchangeChild}
                      />
                    )}
                  </div>
                ))}
              {/* {data &&
                data.length > 0 &&
                data?.map((d, index) =>
                  // check every one data like drinks or
                  c?.modifier_group_ids?.includes(d?.id) ? (
                    <div>
                      {d?.minPermitted > 0 && d?.maxPermittedUnique == 1 && (
                        <>
                          <OnePermitted
                            modifier={d}
                            showChildoneP={showChildoneP}
                            handleChange={handleChange}
                            setOptionsPrice={setOptionsPrice}
                            setExtraQuantity={setExtraQuantity}
                            setCalculatedPrice={setCalculatedPrice}
                            calculatedPrice={calculatedPrice}
                            priceToShow={priceToShow}
                            handleClose={handleClose}
                            setShowChildP={setShowChildP}
                            handleOpenn={handleOpenn}
                            openn={openn}
                            c={c}
                            handleClosee={handleClosee}
                            handleChangeChild={handleChangeChild}
                            setZeros={setZeros}
                            errReq={errReq}
                            optionsPriceChild={optionsPriceChild}
                            setOptionsPriceChild={setOptionsPriceChild}
                            checkSumM={checkSumM}
                            checkSumAdd={checkSumAdd}
                            setMaxErr={setMaxErr}
                            summErr={summErr}
                            checkedModifiers={checkedModifiers}
                            setCheckedModifiers={setCheckedModifiers}
                            checkedOptions={checkedOptions}
                            handleChooseModifires={handleChooseModifires}
                            checkBoxchangeChild={checkBoxchangeChild}
                          />
                        </>
                      )}
                      {d?.minPermitted == 0 && d?.maxPermittedUnique == 1 && (
                        <ChangeItem
                          id={d?.id}
                          zeros={zeros}
                          setZeros={setZeros}
                          modifiersGroup={data}
                          modifier={d}
                          showResults={showResults}
                          setExtraQuantity={setExtraQuantity}
                          extraQuantity={extraQuantity}
                          optionsPrice={optionsPrice}
                          handleChooseModifires={handleChooseModifires}
                          setOptionsPrice={setOptionsPrice}
                          checkedModifiers={checkedModifiers}
                          setCheckedModifiers={setCheckedModifiers}
                          checkedOptions={checkedOptions}
                          errReq={errReq}
                          checkSumAdd={checkSumAdd}
                          checkSumM={checkSumM}
                          setMaxErr={setMaxErr}
                          summErr={summErr}
                          handleChangeChild={handleChangeChild}
                          optionsPriceChild={optionsPriceChild}
                          checkBoxchange={checkBoxchange}
                          setOptionsPriceChild={setOptionsPriceChild}
                          handleChange={handleChange}
                          setCalculatedPrice={setCalculatedPrice}
                          calculatedPrice={calculatedPrice}
                          priceToShow={priceToShow}
                          handleClose={handleClose}
                          setShowChildP={setShowChildP}
                          handleOpenn={handleOpenn}
                          openn={openn}
                          c={c}
                          handleClosee={handleClosee}
                          checkBoxchangeChild={checkBoxchangeChild}
                        />
                      )}

                      {d?.minPermitted == 0 && d?.maxPermittedUnique != 1 && (
                        <ChangeItem
                          id={d?.id}
                          zeros={zeros}
                          setZeros={setZeros}
                          modifiersGroup={data}
                          modifier={d}
                          showResults={showResults}
                          setExtraQuantity={setExtraQuantity}
                          extraQuantity={extraQuantity}
                          optionsPrice={optionsPrice}
                          handleChooseModifires={handleChooseModifires}
                          setOptionsPrice={setOptionsPrice}
                          checkedModifiers={checkedModifiers}
                          setCheckedModifiers={setCheckedModifiers}
                          checkedOptions={checkedOptions}
                          errReq={errReq}
                          checkSumAdd={checkSumAdd}
                          checkSumM={checkSumM}
                          errors={errors[d.id]}
                          setMaxErr={setMaxErr}
                          summErr={summErr}
                          handleChangeChild={handleChangeChild}
                          optionsPriceChild={optionsPriceChild}
                          setOptionsPriceChild={setOptionsPriceChild}
                          checkBoxchange={checkBoxchange}
                          handleChange={handleChange}
                          setCalculatedPrice={setCalculatedPrice}
                          calculatedPrice={calculatedPrice}
                          priceToShow={priceToShow}
                          handleClose={handleClose}
                          setShowChildP={setShowChildP}
                          handleOpenn={handleOpenn}
                          openn={openn}
                          c={c}
                          handleClosee={handleClosee}
                          checkBoxchangeChild={checkBoxchangeChild}
                        />
                      )}

                      {d?.minPermitted > 0 && d?.maxPermittedUnique != 1 && (
                        <ChangeItem
                          id={d?.id}
                          zeros={zeros}
                          setZeros={setZeros}
                          modifiersGroup={data}
                          modifier={d}
                          showResults={showResults}
                          setExtraQuantity={setExtraQuantity}
                          extraQuantity={extraQuantity}
                          optionsPrice={optionsPrice}
                          handleChooseModifires={handleChooseModifires}
                          setOptionsPrice={setOptionsPrice}
                          checkedModifiers={checkedModifiers}
                          setCheckedModifiers={setCheckedModifiers}
                          checkedOptions={checkedOptions}
                          errReq={errReq}
                          summErr={summErr}
                          checkSumAdd={checkSumAdd}
                          checkSumM={checkSumM}
                          setMaxErr={setMaxErr}
                          errors={errors[d.id]}
                          handleChangeChild={handleChangeChild}
                          optionsPriceChild={optionsPriceChild}
                          setOptionsPriceChild={setOptionsPriceChild}
                          checkBoxchange={checkBoxchange}
                          handleChange={handleChange}
                          setCalculatedPrice={setCalculatedPrice}
                          calculatedPrice={calculatedPrice}
                          priceToShow={priceToShow}
                          handleClose={handleClose}
                          setShowChildP={setShowChildP}
                          handleOpenn={handleOpenn}
                          openn={openn}
                          c={c}
                          handleClosee={handleClosee}
                          checkBoxchangeChild={checkBoxchangeChild}
                        />
                      )}
                    </div>
                  ) : (
                    ''
                  )
                )} */}
              {/* </Paper> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid lg={6}>
              <div
                style={{
                  padding: '1rem 2.5rem 1rem 1rem',
                  // justifyContent: 'space-around',
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  $
                  {calculatedPrice - c?.price !== 0
                    ? parseFloat(
                        (
                          calculatedPrice +
                          optionsPrice +
                          optionsPriceChildCheckbox
                        )
                          ?.toString()
                          ?.replaceAll(',', '')
                      )?.toFixed(2)
                    : parseFloat(
                        (priceToShow + optionsPrice + optionsPriceChildCheckbox)
                          ?.toString()
                          ?.replaceAll(',', '')
                      )?.toFixed(2)}
                </Typography>
              </div>
            </Grid>
            {enableAdd ? (
              ''
            ) : (
              <Grid lg={6} style={{ display: 'flex', justifyContent: 'end' }}>
                <div
                  style={{
                    display: 'flex',
                    padding: '1rem 2.5rem 1rem 1rem',
                    justifyContent: 'center',
                  }}
                >
                  {checkLicenses('checkout') && (
                    <>
                      {user &&
                      user?.pickupOrDelivery === 'delivery' &&
                      moment(outofStockTimeDelivery).isAfter(date) ? (
                        <Button
                          style={{
                            border: `2px solid ${environment.mainColor}`,
                            color: environment.mainColor,
                            borderRadius: '20px',
                          }}
                        >
                          {' '}
                          {checkStockDelivery}
                        </Button>
                      ) : (
                        <>
                          {user?.uid &&
                          user &&
                          user.pickupOrDelivery != 'delivery' &&
                          moment(outofStockTimeDineIn).isAfter(date) ? (
                            <Button
                              style={{
                                border: `2px solid ${environment.mainColor}`,
                                color: environment.mainColor,
                                borderRadius: '20px',
                              }}
                            >
                              {' '}
                              {checkStock}
                            </Button>
                          ) : (
                            <>
                              {!added ? (
                                loading ? (
                                  <Snipper color="green" />
                                ) : (
                                  <Button
                                    onClick={handleAddToCart}
                                    style={{
                                      color: 'white',
                                      background:
                                        errReq?.length > 0
                                          ? 'gray'
                                          : environment?.mainColor,
                                      borderRadius: '20px',
                                      padding: '5px 30px 4px 30px',
                                    }}
                                    disabled={errReq?.length > 0}
                                  >
                                    <ShoppingBasketIcon
                                      style={{ marginRight: '15px' }}
                                    />{' '}
                                    {t('menu.addToCart')}{' '}
                                  </Button>
                                )
                              ) : (
                                <p
                                  style={{
                                    display: 'block',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    color: 'green',
                                    fontWeight: 700,
                                  }}
                                >
                                  {t('menu.added')}
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}{' '}
                </div>
              </Grid>
            )}{' '}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CustomizeDialoge;
