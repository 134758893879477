import React from 'react';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Formik } from 'formik';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function Toolbar({
  searchMethod,
  addPage,
  buttonText,
  helperText,
  color,
  careers,
  sort,
  sortText,
  ...props
}) {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box>
      <div style={{ height: '20px' }} />
      <Box sx={{ mt: 3 }}>
        <Box sx={{ maxWidth: '100%' }}>
          <div
            style={{
              display: !xsDown ? 'flex' : 'initial',
              justifyContent: !xsDown ? 'space-between' : 'center',
            }}
          >
            <Formik
              initialValues={{
                searchValue: '',
              }}
              onSubmit={(values) => {
                searchMethod(values.searchValue);
              }}
            >
              {({ handleBlur, handleChange, errors, values, isValid }) => (
                <TextField
                  fullWidth
                  style={{
                    width: !xsDown ? '60%' : '100%',
                    marginBottom: '10px',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <Search />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {/* <Button
                          variant="text"
                          disabled={!!errors.searchValue || !isValid}
                          onClick={() => {}}
                          style={{ color: 'black' }}
                        >
                          Search
                        </Button> */}
                      </InputAdornment>
                    ),
                  }}
                  name="searchValue"
                  onChange={(e) => {
                    handleChange(e); // Update Formik's state
                    searchMethod(e.target.value); // Call searchMethod with the new value
                  }}
                  type="text"
                  onKeyDown={async (e) => {
                    const code = e.keyCode ? e.keyCode : e.which;
                    // on press enter
                    if (code === 13 && isValid) {
                      e.preventDefault();
                      searchMethod(values.searchValue);
                    }
                  }}
                  onBlur={handleBlur('searchValue')}
                  placeholder={helperText}
                  variant="outlined"
                  // helperText={'Press Enter to search'}
                  value={values.searchValue}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'black',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'gray',
                      },
                    },
                  }}
                />
              )}
            </Formik>
            {!xsDown && addPage && !careers && (
              <Box>
                <Button
                  onClick={addPage}
                  variant="contained"
                  style={{
                    color: 'white',
                    backgroundColor: color,
                    fontWeight: 'bold',
                  }}
                >
                  {buttonText}
                </Button>
                {sort && (
                  <Button
                    onClick={sort}
                    variant="contained"
                    style={{
                      color: 'white',
                      backgroundColor: color,
                      fontWeight: 'bold',
                    }}
                  >
                    {sortText}
                  </Button>
                )}
              </Box>
            )}
          </div>
        </Box>
        {xsDown && addPage && (
          <div>
            <Box>
              <Button
                onClick={addPage}
                variant="contained"
                style={{
                  color: 'white',
                  backgroundColor: color,
                  width: '100%',
                  height: '55px',
                }}
              >
                {buttonText}
              </Button>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
}

export default Toolbar;
