import { useEffect, useState } from 'react';
import { connectRestaurantDb, getAppLicenses } from './firebase';

import { useDispatch, useSelector } from 'react-redux';
import { getSuccess } from 'libs/redux-slices/src/lib/slices/licensesSlice';
export function FirebaseService({ children, configs, restaurantId , isRoot }) {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!isRoot){
    if (configs && restaurantId ) {
      connectToFirebase(configs, restaurantId);
    }
  }
  }, [configs, restaurantId]);

  const connectToFirebase = async (configs, restaurantId) => {
    const isReady = await connectRestaurantDb(restaurantId, configs);
    setIsReady(isReady);
    const res = await getAppLicenses(restaurantId);

    dispatch(getSuccess(res));
  };

  return (
    <div style={{ flex: 1 }}>
      {isReady ? (
        <div style={{ flex: 1 }}>{children}</div>
      ) : (
        
        <div style={{ flex: 1 }}></div>
      )}
     {isRoot ? (
        <div style={{ flex: 1 }}>{children}</div>
      ) : (
        
        <div style={{ flex: 1 }}></div>
      )}
    </div>

  );
}
export default FirebaseService;
