import React from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
import { environment } from '../../../environments/environment';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Menu/index.css';
import aboutus from '../../../assets/aboutus.png';
import footer from '../../../assets/background.png';
import { useTranslation } from 'react-i18next';

function Aboutus() {
  const [t, i18n] = useTranslation();

  return (
    <div>
      {environment.restaurantId === 'istanbulDk' && (
        <>
          <img
            alt="tesxt"
            src={aboutus} // use normal <img> attributes as props
            width="100%"
            style={{ height: '550px' }}
          />
          <Container maxWidth={'lg'} style={{ marginTop: '20px' }}>
            <div
              style={{
                position: 'relative',

                backgroundImage: `url(${footer})`,
              }}
            >
              <Container maxWidth={'xl'}>
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginTop: '40px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    style={{
                      alignItems: 'center',
                      marginTop: '10px',
                      marginBottom: '30px',
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{
                        color: environment.mainColor,
                        fontWeight: 'bold',
                        fontFamily: `Hanalei Fill, cursive`,
                      }}
                      sx={{ fontSize: { xs: '40px', md: '60px', lg: '40px' } }}
                    >
                      {t("aboutUs.aboutTitle")}
                    </Typography>
                    <Grid
                      item
                      xs={2}
                      direction="row"
                      style={{
                        display: 'flex',
                        alignItems: 'center ',
                        marginBottom: '10px',
                      }}
                    >
                      <span
                        className="dot"
                        style={{ backgroundColor: environment.mainColor }}
                      ></span>

                      <Divider
                        style={{
                          marginBottom: '5px',
                          marginTop: '5px',
                          height: '2px',
                          backgroundColor: environment.mainColor,
                          width: '50%',
                          marginLeft: '8px',
                        }}
                      />
                    </Grid>

                    <Typography
                      variant="body1"
                      style={{
                        color: 'black',
                      }}
                    >
                      {t("aboutUs.aboutDescOne")}

                      <br />
                      {t("aboutUs.aboutDescTwo")}

                      <br />
                      {t("aboutUs.aboutDescThree")}

                      <br />
                      {t("aboutUs.aboutDescFour")}

                      <br />
                      {t("aboutUs.aboutDescFive")}

                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Container>
        </>
      )}
    </div>
  );
}
export default Aboutus;
