import firebase from 'firebase';

export default firebase;
const tableTuckRootConfig = {
  apiKey: 'AIzaSyAz2iHGtFVbcMTlh5rdWOjVIZVHNBsSh2M',
  authDomain: 'wl-restaurants.firebaseapp.com',
  projectId: 'wl-restaurants',
  storageBucket: 'wl-restaurants.appspot.com',
  messagingSenderId: '1079904720323',
  appId: '1:1079904720323:web:931d1d3f0eb88f4cc49976',
  measurementId: 'G-1Q1SSC8ZC5',
};
let rootApp = null;
if (firebase.apps.length) {
  const root = firebase.apps.find((e) => e.name === '');
  if (root) {
    rootApp = root;
  } else {
    rootApp = firebase.initializeApp(tableTuckRootConfig, 'rootTableTuck');
  }
} else {
  rootApp = firebase.initializeApp(tableTuckRootConfig, 'rootTableTuck');
}

export const RootFirestore = firebase.firestore(rootApp);
export const RootStorage = firebase.storage(rootApp);
export const RootAuth = firebase.auth(rootApp);
export var firestore;
export var auth;
export var storage;
export var recaptchaVerifier;
export var _customApp;

// eslint-disable-next-line no-async-promise-executor

export const getAppLicenses = async (restaurantId) => {
  // eslint-disable-next-line no-async-promise-executor
  try {
    const result = await RootFirestore.collection('licenses')
    .where('id', '==', restaurantId).get();
    if (result.docs.length) {
      const licensesList = result.docs[0].data();
      sessionStorage.setItem('licenses', JSON.stringify(licensesList));
      return licensesList;
    } else {
      sessionStorage.removeItem('licenses');
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const connectRestaurantDb = async (restaurantId, config) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const restaurantDoc = await RootFirestore.collection('restaurants')
      .doc(restaurantId)
      .collection('private')
      .doc('key')
      .get();
    if (restaurantDoc.exists) {
      if (firebase.apps.length) {
        const isConnected = firebase.apps.find(
          (app) => app.name === restaurantId
        );
        if (isConnected) {
          _customApp = isConnected;
          recaptchaVerifier = firebase.auth.RecaptchaVerifier;
          firestore = isConnected.firestore();
          storage = isConnected.storage();
          auth = isConnected.auth();
          resolve(true);
          return;
        }
      }
      const customApp = firebase.initializeApp(config, restaurantId);
      _customApp = customApp;
      recaptchaVerifier = firebase.auth.RecaptchaVerifier;
      firestore = customApp.firestore();
      storage = customApp.storage();
      auth = customApp.auth();
      return resolve(true);
    }
    return reject('Could not find the db you asked for.');
  });
};

export const getDocFromCollection = (collectionName, docId) => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection(collectionName).doc(docId);
    ref
      .get()
      .then((result) => {
        if (result && result.exists) {
          return resolve({
            ...result.data(),
            docId: result.id,
          });
        } else {
          return resolve(null);
        }
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const updateDocFromCollection = (
  collectionName,
  docId,
  updates,
  merge = true
) => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection(collectionName).doc(docId);
    ref
      .set(
        {
          ...updates,
        },
        {
          merge: merge,
        }
      )
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
