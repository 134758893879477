import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { environment } from '../../../environments/environment';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { storage } from '@tabletuck/firebase-services';

import FacebookIcon from '@mui/icons-material/Facebook';
import '../Menu/index.css';
import footer from '../../../assets/career.png';
import { Box } from '@mui/system';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { firestore } from '../../../../../../libs/firebase-service/src';
import CustomPhoneNumber from '../../components/PhoneNumber/PhoneInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';
import { Helmet } from 'react-helmet';

function Careers() {
  const [t, i18n] = useTranslation();

  const [startDate, setStartDate] = useState(new Date());
  const [url, setUrl] = React.useState('');
  const [error, setErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [file, setFile] = useState('');
  const re = /\S+@\S+\.\S+/;
  const pattern = /^[a-zA-Z0-9]+@[a-zA-Z0-9.]+$/;

  const handleSubmit = async (values) => {
    setIsLoadingSubmit(true);
    const doc = await firestore.collection('careers').doc();
    const updateDoc = await firestore.collection('restaurant').doc();
    let phoneNumbert = values.phoneNumber.toString();
    phoneNumbert = phoneNumbert.replace(/\s/g, '');
    phoneNumbert = phoneNumbert.replace(/-/g, '');
    phoneNumbert = phoneNumbert.replace(/[{()}]/g, '');
    phoneNumbert = '+1' + phoneNumbert;

    if (url.length > 0) {
      setErr('');
      await firestore

        .collection('careers')
        .doc(doc.id)
        .set({
          fullName: values.fullName,
          position: values.position,
          phoneNumber: phoneNumbert,
          message: values.message,
          email: values.email,
          workHours: values.workHours,
          DateToStart: startDate,
          resume: url,
          id: doc.id,
          createdAt: new Date(),
        })

        .then(async () => {
          values.fullName = '';
          values.position = '';
          values.phoneNumber = '';
          values.message = '';
          values.email = '';
          values.workHours = '';
          setUrl('');
          setIsLoadingSubmit(false);
        })
        .catch((e) => {
          setIsLoadingSubmit(false);
        });

      await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .set(
          {
            update_items_id: updateDoc.id,
          },
          { merge: true }
        )

        .catch((e) => {
          setIsLoadingSubmit(false);
          console.log('err:', e);
        });

      await fetchData(
        `${environment?.apiUrl}/sendEmail?restaurantId=${environment.restaurantId}`,
        'post',
        {
          type: 'careers',
          id: doc.id,
        }
      )
        .then((c) => {
          setFile(null);
          console.log('success');
          console.log(c);
          handleToaster('success', 'Uploaded successfully!');
        })
        .catch((e) => {
          console.log('api error catch', e);
          handleToaster('error', e);
        });
    } else setErr('Please upload your resume');

    setIsLoadingSubmit(false);
  };

  const deleteImage = () => {
    if (file) {
      const storageRef = storage.ref(`/careers/${file}`);
      storageRef
        .delete()
        .then(() => {
          console.log('Image deleted successfully.');
          setFile(null);
        })
        .catch((error) => {
          console.error('Error deleting image: ', error);
        });
    }
  };
  const handleImagrUrl = (e) => {
    setIsLoading(true);
    setErr('');
    let file = Object.values(e.target.files)[0];
    setFile(file?.name);
    if (
      (file && file.length !== 0) ||
      (file && file !== '' && typeof file !== 'string')
    ) {
      const uploadTask = storage.ref(`/careers/${file.name}`).put(file);
      uploadTask.on(
        'state_changed',
        (snapShot) => {},
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref('careers')
            .child(file.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setUrl(fireBaseUrl);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      );
    } else {
      setUrl(file);
      setIsLoading(false);
    }
  };

  const validationScheme = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z\s]+$/, ' Name  should contain only letters and spaces')
      .min(3, 'min char must equal 3')
      .max(30, 'min char must equal 30')
      .required(`${t('careerss.nameErr')}`),
    position: yup
      .string()
      .matches(/^\S*$/, 'String should not contain spaces')
      .min(3, 'min char must equal 3')
      .max(30, 'min char must equal 30')
      .required(`${t('careerss.positionErr')}`),
    message: yup
      .string()
      .matches(/^(?!\s*$).*/, 'should not contain only spaces')
      .min(3, 'min char must equal 3')
      .max(250, 'min char must equal 250')
      .required(`${t('contactUs.messageErr')}`),

    email: yup
      .string()
      .required(`${t('careerss.emailErr')}`)
      .email('Please provide a valid email'),
    phoneNumber: yup
      .string()
      .min(14, 'Phone number must be at least 10 characters')
      .max(14, 'Phone number must not exceed 10 characters')
      .required(`${t('careerss.phoneErr')}`),
    workHours: yup
      .number()
      .max(24, 'working hours should not exceeds 24 hours')
      .typeError(`${t('careerss.workNumber')}`)
      .required(`${t('careerss.workErr')}`)
      .min(1),
  });

  return (
    <>
      {environment?.restaurantId === 'istanbulDk' ||
        (environment.restaurantId === 'taqwasbakery' && (
          <Helmet>
            <title>Taqwa's Bakery and Restaurant | Careers</title>
          </Helmet>
        ))}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {environment.restaurantId === 'istanbulDk' ||
        (environment.restaurantId === 'taqwasbakery' && (
          <img
            alt="tesxt"
            height="90%"
            src={footer} // use normal <img> attributes as props
            width="100%"
          />
        ))}
      <Container maxWidth={'md'}>
        <Formik
          initialValues={{
            fullName: '',
            position: '',
            phoneNumber: '',
            message: '',
            email: '',
            workHours: '',
            DateToStart: startDate,
            resume: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationScheme}
        >
          {({
            handleBlur,
            handleChange,
            values,
            errors,
            handleSubmit,
            touched,
          }) => (
            <div>
              <Divider />
              <div style={{ height: '110px' }} />
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('careerss.JoinUs')}
                </Typography>
              </Grid>
              <br />
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography variant="h6" style={{ color: 'black' }}>
                  {t('careerss.careersDesc')}
                </Typography>
              </Grid>
              <div style={{ height: '15px' }} />
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={
                      i18n.language === 'ar' || i18n.language === 'he'
                        ? ''
                        : t('careerss.fullName')
                    }
                    placeholder={t('careerss.fullName')}
                    name="fullName"
                    multiline
                    onChange={handleChange('fullName')}
                    onBlur={handleBlur('fullName')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.fullName}
                    error={errors.fullName && touched.fullName}
                    variant="outlined"
                  />
                  {errors.fullName && touched.fullName ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.fullName}
                    </span>
                  ) : null}
                </Grid>
                <div style={{ height: '30px' }} />
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={t('careerss.email')}
                    name="email"
                    multiline
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    type="email"
                    value={values.email}
                    error={errors.email && touched.email}
                    variant="outlined"
                  />
                  {errors.email && touched.email ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {!re.test(values?.email)
                        ? 'Please enter a valid email.'
                        : errors.email}
                    </span>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={6}>
                  <Field
                    as={CustomPhoneNumber}
                    fullWidth
                    name="phoneNumber"
                    variant="outlined"
                    label={t('careerss.phoneNumber')}
                    placeholder={t('careerss.holderPhoneNumber')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <IconButton fontSize="small" style={{ color: 'black' }}>
                          <PlusOneIcon
                            fontSize="small"
                            style={{ color: 'black' }}
                          />
                        </IconButton>
                      ),
                    }}
                    error={touched.phoneNumber && errors.phoneNumber && true}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Grid>{' '}
                <div style={{ height: '30px' }} />
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={t('careerss.workHours')}
                    name="workHours"
                    multiline
                    onChange={handleChange('workHours')}
                    onBlur={handleBlur('workHours')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.workHours}
                    error={errors.workHours && touched.workHours}
                    variant="outlined"
                  />
                  {errors.workHours && touched.workHours && (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.workHours}
                    </span>
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={t('careerss.position')}
                    name="position"
                    multiline
                    onChange={handleChange('position')}
                    onBlur={handleBlur('position')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.position}
                    error={errors.position && touched.position}
                    variant="outlined"
                  />
                  {errors.position && touched.position ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.position}
                    </span>
                  ) : null}
                </Grid>
                <Grid item md={12}>
                  {t('careerss.startDate')}

                  <Box style={{ position: 'relative', zIndex: 9999 }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    helperText=""
                    required
                    label={t('careerss.message')}
                    name="message"
                    multiline
                    rows={4}
                    onChange={handleChange('message')}
                    onBlur={handleBlur('message')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.message}
                    error={errors.message && touched.message}
                    variant="outlined"
                  />
                  {errors.message && touched.message ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.message}
                    </span>
                  ) : null}
                </Grid>
                <Grid item md={12} xs={12}>
                  {file && !isLoading && (
                    <Chip
                      label={file}
                      onDelete={deleteImage}
                      deleteIcon={<DeleteIcon />}
                      variant="outlined"
                    />
                  )}
                  <form onSubmit={() => {}} className="form">
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        backgroundColor: !isLoading
                          ? environment.mainColor
                          : 'gray',
                        color: !isLoading ? 'white' : 'black',
                        marginTop: '10px',
                      }}
                      disabled={isLoading}
                    >
                      {isLoading
                        ? `${t('careerss.loading')}`
                        : `${t('careerss.upLoad')}`}
                      <input
                        type="file"
                        hidden
                        accept="image/png, image/jpeg, .pdf"
                        onChange={(e) => {
                          handleImagrUrl(e);
                          e.target.value = null;
                        }}
                      />
                    </Button>
                  </form>
                  <p style={{ color: 'red' }}>{error}</p>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2,
                }}
                style={{ marginLeft: '20px' }}
              >
                <Button
                  style={{
                    color: 'white',
                    backgroundColor: environment.mainColor,
                    width: '150px',
                  }}
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    isLoadingSubmit || pattern.test(values.email) != true
                  }
                >
                  {isLoadingSubmit
                    ? `${t('careerss.loading')}`
                    : `${t('careerss.send')}`}
                </Button>
              </Box>
              <div style={{ height: '30px' }} />
            </div>
          )}
        </Formik>
      </Container>
    </>
  );
}
export default Careers;
