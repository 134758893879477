import React, { useEffect, useState } from 'react';
import PaymentIcon from '@mui/icons-material/Payment';
import { Button, useMediaQuery } from '@mui/material';
import visa from '../../../assets/visa.png';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useSelector } from 'react-redux';
import { firestore } from '@tabletuck/firebase-services';
import { useTheme } from '@emotion/react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { async } from 'regenerator-runtime';
import { auth } from '../../../../../../libs/firebase-service/src';
import amex from '../../../assets/amex.png';
import discover from '../../../assets/dicover.png';
import dinars from '../../../assets/dinars.png';
import geniric from '../../../assets/geniric.png';
import jbc from '../../../assets/geniric.png';
import mastercard from '../../../assets/mastercard.png';
import unionpay from '../../../assets/unionpay.png';
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const nav = useNavigate();
  const token = useSelector((state) => state.authReducer.token);
  const [open, setOpen] = useState(false);
  const [selectCardToDelete, setSelectCardToDelete] = useState('');
  const [t, i18n] = useTranslation();

  const handleDelete = async (cc) => {
    try {
      auth?.currentUser.getIdToken().then(async (token) => {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: token,
        };
        await axios.post(
          `${environment?.apiUrl}/deleteCard?restaurantId=${environment.restaurantId}`,
          {
            token: selectCardToDelete?.id,
          },
          { headers }
        );
      });
    } catch (err) {
      console.log(err, 'err');
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [methods, setMethods] = useState(['']);
  const [loading, setLoading] = useState('');
  let getData = async () => {
    try {
      const querySnapshot = await firestore
        .collection(
          `users/${auth?.currentUser?.uid}/private_data/cards/sources`
        )
        .get();

      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setMethods(arr);
    } catch (e) {
      console.log('Error in payment:', e.message);
    }
  };
  useEffect(() => {
    getData();
  }, [methods]);
  // useEffect(() => {
  //   const unsubscribe = firestore
  //     .collection(`users/${auth?.currentUser?.uid}/private_data/cards/sources`)
  //     .onSnapshot((querySnapshot) => {
  //       const arr = [];
  //       querySnapshot.forEach((doc) => {
  //         arr.push(doc.data());
  //       });
  //       setMethods(arr);
  //       setLoading(false);
  //     });
  //   return () => unsubscribe();

  // }, [])

  return (
    <div
      className="mt-3.5"
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
    >
      <div>
        <Button
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          onClick={() => nav('/payment')}
          variant="text"
          style={{
            width: '100%',
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            color: environment.mainColor,
            padding: '10px',
            borderRadius: '20px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          <PaymentIcon /> {t('profilee.addAPaymentMethod')}
        </Button>
      </div>
      {methods.map((item) => (
        <div>
          <div
            class="flex flex-col md:flex-row  rounded-lg bg-white shadow-lg"
            style={{
              marginTop: '25px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '5px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={
                  item?.brand === 'American Express'
                    ? amex
                    : item?.brand === 'Diners Club'
                    ? dinars
                    : item?.brand === 'Discover'
                    ? discover
                    : item?.brand === 'JCB'
                    ? jbc
                    : item?.brand === 'Visa'
                    ? visa
                    : item?.brand === 'MasterCard'
                    ? mastercard
                    : item?.brand === 'UnionPay'
                    ? unionpay
                    : geniric
                }
                alt="visa"
                style={{
                  color: 'white',
                  width: '50px',
                  height: '50px',
                  borderRadius: '5px',
                  marginRight: 5,
                }}
              />
              <h5
                class="text-gray-100 text-lg font-small "
                style={{ color: 'gray' }}
              >
                ****{item.last4}
              </h5>
            </div>
            <DeleteIcon
              style={{ color: environment.mainColor }}
              onClick={() => {
                setOpen(true);
                setSelectCardToDelete(item);
              }}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('profilee.deleteCreditCard')}
                </DialogContentText>
              </DialogContent>
              <DialogActions dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                <Button
                  style={{ color: environment?.mainColor }}
                  onClick={() => handleDelete(item)}
                >
                  {t('profilee.yes')}
                </Button>
                <Button
                  style={{ color: environment?.mainColor }}
                  onClick={handleClose}
                  autoFocus
                >
                  {t('profilee.cancel')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Payment;
