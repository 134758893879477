import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

function LoginHeader(props) {
  const classes = makeStyles(() => ({
    root: {
      flexGrow: 1,
      backgroundColor: props.color,
      margin: 0,
    },
  }));
  

  return (
    <AppBar
      position="absolute"
      className={classes.root}
      style={{ backgroundColor: props.color }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Typography
            noWrap
            component="a"
            style={{ display: 'flex', cursor: 'pointer' }}
            className={classes.title}
            variant="h6"
            sx={{
              mr: 2,
              // ml:2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 700,
              color: 'white',
            }}
          >
            {/* {props.title} */}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default LoginHeader;
