import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Button, CardMedia } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '@tabletuck/firebase-services';
import { CustomButton, MenuCard } from '@tabletuck/components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import background from '../../assets/banner.png';
import CustomizeDialoge from '../components/CustomizeDialoge/CustomizeDialoge';
import { environment } from '../../environments/environment';
import { setCart } from '@tabletuck/redux-slices';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { auth } from '../../../../../libs/firebase-service/src';
import moment from 'moment';
import { setUser, updateUser } from '../../../../../libs/redux-slices/src';
import {
  setOutOStockItemsDelivery,
  setOutOStockItemsDinein,
} from '../../../../../libs/redux-slices/src';

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [items, setItems] = useState([]);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const [data, setData] = React.useState([]);
  const [defs, setDefs] = useState([]);
  const [custm, setcustom] = useState('');
  const [extraquantity, setExtraquantity] = useState({});
  const [extraQuantity, setExtraQuantity] = useState({});

  const token = useSelector((state) => state.authReducer.token);
  const [added, setAdded] = useState(false);
  // const [user, setUser] = useState([]);
  const [favorites, setfav] = useState([]);
  const [option, setOption] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [optionsPrice, setOptionsPrice] = useState(0);
  const check = window?.localStorage?.getItem('items');
  const [checkStock, setCheckStock] = useState('');
  const [outofStockDineIn, setOutOfStockDineIn] = useState('');
  const [checkStockDelivery, setCheckStockDelivery] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const [storeId, setStoreId] = useState('');
  const [outofStockTimeDineIn, setOutofStockTimeDineIn] = useState(null);
  const [outofStockTimeDelivery, setOutofStockTimeDelivery] = useState(null);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const user = useSelector((state) => state.authReducer.userData);
  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const stores = useSelector((state) => state.shopReducer.stores);
  let date = new Date();
  const getUser = async () => {
    setfav(user?.favorites);
  };

  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          stores?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };

  const checkoutOfstockDelivery = () => {
    if (outofStockDelivery && outofStockDelivery?.length === 0) {
      setCheckStockDelivery('');
    } else
      outofStockDelivery &&
        outofStockDelivery.filter((c) => {
          if (c?.id === id && user && user?.pickupOrDelivery === 'delivery') {
            setCheckStockDelivery('This item is out of stock');
            setOutofStockTimeDelivery(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStockDelivery('');
        });
  };
  useEffect(() => {
    checkoutOfstockDelivery();
    // auth?.currentUser?.uid&& getUser();
  }, [outofStockDelivery, setCheckStockDelivery]);
  const getStorePickupDinein = () => {
    stores?.filter((store) => {
      if (store?.id === user?.defaultStore) {
        setOutOfStockDineIn(store?.out_of_stock);
      }
    });
  };
  const outofStock = async () => {
    if (outofStockDineIn && outofStockDineIn?.length === 0) {
      setCheckStock('');
    } else
      outofStockDineIn &&
        outofStockDineIn.filter((c) => {
          if (c.id === id && user?.pickupOrDelivery != 'delivery') {
            setCheckStock('This item is out of stock');
            setOutofStockTimeDineIn(
              new Date(c?.out_of_stock_end_date?.seconds * 1000)
            );
          } else setCheckStock('');
        });
  };

  useEffect(() => {
    getStorePickupDinein();
    outofStock();
  }, [
    favorites,
    user,
    // outofStockDineIn,
    // outofStockTimeDineIn,
    // // setOutofStockTimeDineIn,
  ]);
  useEffect(() => {
    if (user?.uid) {
      getStorePickupDinein();
      getStoreDelivery();
    }
  }, [user, stores]);
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'checkout':
        return check != 'true' ? licenses.frontend.checkout : false;
        return false;

      default:
        return false;
    }
  };

  useEffect(() => {
    getUser();
  }, [auth?.currentUser?.uid]);

  useEffect(() => {
    if (auth?.currentUser?.uid) checkoutOfstockDelivery();
  }, [
    favorites,
    user,
    outofStockDelivery,
    user?.defaultDeliveryAddress,
    storeId,
    // outofStockTimeDelivery,
    checkStockDelivery,
    auth?.currentUser?.uid,
  ]);

  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraquantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item.modifier_group_ids.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setcustom((c) => (c ? c + `, ${opt.title}` : opt.title));
              setDefs((defs) =>
                defs.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
        setOptionsPrice(o > 0 ? o : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (items.length) {
      getDefaults();
    }
  }, [useParams, id, items.length]);
  const addFavorite = async () => {
    try {
      let arr = user?.favorites ? user?.favorites : [];
      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: [...arr, id],
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, favorites: [...arr, id] };
          dispatch(setUser({ userData: updatedUser }));
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const removeFavoriteTasker = async (taskerID) => {
    // await firestore
    //   .collection('users')
    //   .doc(auth?.currentUser?.uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     if (querySnapshot && querySnapshot.exists) {
    //       setUser(querySnapshot.data());
    //     }
    //   });
    setUser(user);

    try {
      let arr = user?.favorites;

      arr = arr.filter(function (item) {
        return item !== id;
      });

      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: arr,
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, favorites: arr };
          dispatch(setUser({ user: updatedUser }));
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getModifiers = async () => {
    setData(modifiers);
  };

  useEffect(() => {
    getModifiers();
  }, []);
  // const componentDidMount = async () => {
  //   try {
  //     await axios
  //       .get(
  //         `https://us-central1-wl-restaurants.cloudfunctions.net/api/getItems?restaurantId=istanbulDk`
  //       )
  //       .then((res) => {
  //         const item = [res.data];
  //         setItems(item[0].items);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
    setExtraQuantity({});
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onAddToCartClick = async (item, index) => {
    let totalPrice = item.price + optionsPrice + optionsPrice1;
    try {
      if (auth?.currentUser?.uid) {
        const bagitems = user?.bagItems;
        const newOption = {
          price: item.price,
          calculatedPrice: Number(totalPrice.toFixed(2)),
          image: item.item_image_url,
          itemId: item.id,
          specialInstructions: '',
          title: item.name,
          quantity: 1,
          customization: extraquantity,

          dinein_enabled: item?.dinein_enabled,
          delivery_enabled: item?.delivery_enabled,
          pickup_enabled: item?.pickup_enabled,
        };
        let items = [];
        if (bagitems?.length > 0) {
          items = bagitems.map((element) => ({ ...element }));
          let found = false;
          for (let index = 0; index < items.length; index++) {
            const element = items[index];
            if (element.itemId === newOption.itemId) {
              element.quantity = element.quantity + 1;
              element.calculatedPrice = totalPrice * element.quantity;
              found = true;
            }
          }
          if (!found) {
            items = [...items, newOption];
          }
        } else {
          items = [newOption];
        }
        const updateDoc = await firestore.collection('restaurant').doc();
        await firestore.collection('users').doc(auth?.currentUser?.uid).set(
          {
            bagItems: items,
          },
          { merge: true }
        );

        await firestore
          .collection('restaurant')
          .doc('Restaurant')
          .set(
            {
              update_items_id: updateDoc.id,
            },
            { merge: true }
          )
          .then(() => {
            dispatch(setCart(items));
            const updatedUser = { ...user, bagItems: items };
            dispatch(setUser({ userData: updatedUser }));
            setAdded(true);
            setTimeout(() => {
              setAdded(false);
            }, 2000);
          });
      } else navigate('/signup');
    } catch (e) {
      console.log(e.message);
    }
  };
  const getBagItems = async () => {
    const bags = user?.bagItems;
    // setBagItems(bags);
    return bags;
  };

  return (
    <div style={{ marginTop: '180px' }}>
      {/* <img
       src={background}
       alt='background'
     /> */}

      {items &&
        items.length > 0 &&
        items.map((c, index) => {
          return (
            c?.item_state === 'available' && (
              <div>
                {c.id === id && (
                  <div key={c.id}>
                    <Container maxWidth={'lg'}>
                      <div class="md:flex  ">
                        <div class="contents">
                          <div class="flex-1 ">
                            <div
                              style={{
                                border: `2px solid ${environment.mainColor}`,
                                padding: '10px',
                                borderRadius: '20px',
                              }}
                            >
                              <>
                                <Carousel
                                  width={'100%'}
                                  showArrows={true}
                                  infiniteLoop={true}
                                  autoPlay={true}
                                  showThumbs={false}
                                  showStatus={false}
                                  height={'400px'}
                                  showIndicators={false}
                                  verticalSwipe="standard"
                                >
                                  {c?.listOfImages.map((cc) => (
                                    <CardMedia
                                      style={{ height: 400 }}
                                      image={cc}
                                    />
                                  ))}
                                </Carousel>
                              </>
                            </div>{' '}
                          </div>
                          <div
                            class="flex-1 "
                            style={{ marginLeft: '28px', marginTop: '10px' }}
                          >
                            <Typography
                              variant="h3"
                              style={{ fontWeight: 'bold' }}
                            >
                              {c.name} <br />
                            </Typography>
                            <br />
                            <Typography variant="h6" style={{ color: 'gray' }}>
                              {c.description}
                            </Typography>
                            <br />
                            <Typography
                              variant="h5"
                              style={{
                                fontWeight: 'bold',
                                color: environment.mainColor,
                              }}
                            >
                              $
                              {optionsPrice1 >= 0 && optionsPrice >= 0
                                ? Number(
                                    c.price + optionsPrice1 + optionsPrice
                                  ).toFixed(2)
                                : c?.price}
                            </Typography>
                            <br />
                            {checkLicenses('checkout') && (
                              <>
                                {auth?.currentUser?.uid && (
                                  <div style={{ marginBottom: '16px' }}>
                                    {user?.favorites?.includes(id) ? (
                                      <FavoriteIcon
                                        style={{
                                          color: environment.mainColor,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          removeFavoriteTasker(id);
                                        }}
                                      />
                                    ) : (
                                      <FavoriteBorderIcon
                                        style={{
                                          color: environment.mainColor,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          addFavorite();
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                                {defs.length > 0
                                  ? defs.map((item) => (
                                      <Typography
                                        variant="h6"
                                        style={{
                                          color: environment.mainColor,
                                        }}
                                      >
                                        {item[0]} {item[1]}
                                      </Typography>
                                    ))
                                  : ''}

                                {user &&
                                user?.pickupOrDelivery === 'delivery' &&
                                moment(outofStockTimeDelivery).isAfter(date) ? (
                                  <Button
                                    style={{
                                      border: `2px solid ${environment.mainColor}`,
                                      color: environment.mainColor,
                                      borderRadius: '20px',
                                    }}
                                  >
                                    {' '}
                                    {checkStockDelivery}
                                  </Button>
                                ) : (
                                  <>
                                    {user?.uid &&
                                    user &&
                                    user.pickupOrDelivery != 'delivery' &&
                                    moment(outofStockTimeDineIn).isAfter(
                                      date
                                    ) ? (
                                      <Button
                                        style={{
                                          border: `2px solid ${environment.mainColor}`,
                                          color: environment.mainColor,
                                          borderRadius: '20px',
                                        }}
                                      >
                                        {' '}
                                        {checkStock}
                                      </Button>
                                    ) : (
                                      <>
                                        <div class="flex">
                                          <div class="flex-1">
                                            <Grid
                                              sx={{
                                                width: {
                                                  xs: '100%',
                                                  md: '60%',
                                                  lg: '60%',
                                                },
                                              }}
                                            >
                                              {' '}
                                              {added ? (
                                                <CustomButton
                                                  bg={environment.mainColor}
                                                  buttonText={'Added'}
                                                  onClick={() =>
                                                    onAddToCartClick(c, index)
                                                  }
                                                  admin={false}
                                                />
                                              ) : (
                                                <CustomButton
                                                  bg={environment.mainColor}
                                                  onClick={() =>
                                                    onAddToCartClick(c, index)
                                                  }
                                                  buttonText={'Add to cart'}
                                                  admin={false}
                                                />
                                              )}
                                            </Grid>
                                          </div>
                                          <div class="contents">
                                            <div class="flex-1">
                                              {' '}
                                              {c?.modifier_group_ids?.length >
                                                0 && (
                                                <Button
                                                  style={{
                                                    border: `2px solid ${environment.mainColor}`,
                                                    color:
                                                      environment.mainColor,
                                                    borderRadius: '20px',
                                                    padding:
                                                      '5px 30px 4px 30px',
                                                  }}
                                                  onClick={handleClickOpen}
                                                >
                                                  Customize
                                                </Button>
                                              )}
                                              <CustomizeDialoge
                                                open={open}
                                                handleClose={handleClose}
                                                option={option}
                                                c={c}
                                                priceToShow={
                                                  c?.price + optionsPrice1
                                                }
                                                data={data}
                                                defs={defs}
                                                defaultPrices={optionsPrice1}
                                                extraQuantity={extraQuantity}
                                                setExtraQuantity={
                                                  setExtraQuantity
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}{' '}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '50px',
                          marginBottom: '50px',
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{
                            fontSize: { xs: '40px', lg: '80px' },
                          }}
                          style={{
                            fontFamily: 'Futura Md BT',
                            color: environment.mainColor,
                            fontWeight: 'bold',
                          }}
                        >
                          You May Also Like
                        </Typography>
                      </div>
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        paddingTop="20px"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        {items.map((item, index) =>
                          item?.item_state === 'available' && index <= 2 ? (
                            <Grid item lg={4}>
                              <MenuCard
                                color={environment.mainColor}
                                restaurantId={environment.restaurantId}
                                item={item}
                                key={index}
                                detCallBack={setcustom}
                              />
                            </Grid>
                          ) : (
                            ''
                          )
                        )}
                      </Grid>
                    </Container>
                  </div>
                )}
              </div>
            )
          );
        })}
    </div>
  );
};

export default Details;
