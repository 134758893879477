import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  Button,
  DialogActions,
} from '@mui/material';
import { Formik } from 'formik';
import { environment } from '../../../environments/environment';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { setUser, updateUser } from '../../../../../../libs/redux-slices/src';

const ChangeEmail = ({ open, onClose, setEmail, user, email }) => {
  const [loading, setLoading] = useState('');
  const token = useSelector((state) => state.authReducer.token);
  const userData = useSelector((state) => state.authReducer.userData);


  const handleSubmit = async (values) => {
    setLoading(true);
    const updateDoc = await firestore.collection('restaurant').doc();
    setEmail(values.email);

    await firestore
      .collection('users')
      .doc(userData?.uid)
      .set(
        {
          email: values.email,
        },
        { merge: true }
      )
      .then(() => {
        setLoading(false);
        const updatedUser = { ...userData, email: values.email, };
        dispatch(setUser({ userData: updatedUser }));
      })
      .catch((e) => {
        setLoading(false);
      });
    await firestore
      .collection('restaurant')
      .doc('Restaurant')
      .set(
        {
          update_items_id: updateDoc.id,
        },
        { merge: true }
      )
      .then(() => {
        onClose();
      })
      .catch((e) => {
        console.log('err:', e);
      });

  };

  

  const validationScheme = yup.object().shape({
    email: yup
      .string()
      .email('Email is not valid')
      .required('Please enter your New Email'),
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={'title'} style={{ padding: '30px' }}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            Change Email
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogActions>
          <Formik
            enableReinitialize
            initialValues={{
              email:email ? email : user?.email,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationScheme}
          >
            {({
              handleBlur,
              handleChange,
              values,
              errors,
              handleSubmit,
              touched,
            }) => (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={'Email'}
                    name="email"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    required
                    value={values.email}
                    variant="outlined"
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                  />
                  {errors.email && touched.email ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.email}
                    </span>
                  ) : null}
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        width: '100%',
                        padding: '20px',
                        paddingRight: '0',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        onClick={() => onClose()}
                        style={{ color: 'black' }}
                      >
                        Cancel
                      </Button>
                      <div style={{ width: '30px' }} />
                      <Button
                        oading={loading}
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                        style={{ backgroundColor: environment.mainColor }}
                      >
                        Save Info
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangeEmail;
