import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../src/app/components/local/en.json';
import translationAr from '../src/app/components/local/ar.json';
import translationCh from '../src/app/components/local/ch.json';
import translationEs from '../src/app/components/local/es.json';
import translationHe from '../src/app/components/local/he.json';





import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEn,
  },
  ar: {
    translation: translationAr,
  },
  zh:{
    translation :translationCh
  },
  es:{
    translation :translationEs
  },
  he:{
    translation :translationHe 
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

   
    ns: ['translation'],
  });

export default i18n;
