import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CustomButton } from '@tabletuck/components';
import { Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Box,
} from '@mui/material';
import { auth, firestore } from '@tabletuck/firebase-services';
import { AddToCart, activeMenu, allactiveMenues, menues, setCart, setUser } from '@tabletuck/redux-slices';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function MenuCard(props) {
  const [defs, setDefs] = useState([]);
  const [custm, setcustom] = useState('');
  const [extraQuantity, setExtraQuantity] = useState({});
  const [defaultOption, setDefaultOption] = useState([]);
  const token = useSelector((state) => state.authReducer.token);
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const check = window.localStorage.getItem('items');
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const user = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);

  const menuDocs = useSelector(
    (state) => state.authReducer.menuDocs
  );

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'checkout':
        return check != 'true' ? licenses.frontend.checkout : false;

      default:
        return false;
    }
  };
  const [added, setAdded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraQuantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const mods = modifiers?.filter((mod) =>
        props.item.modifier_group_ids.includes(mod?.id)
      );
      const perimits = mods.filter((opt) => opt?.minPermitted == 1);
      if (perimits?.length > 0) {
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setDefaultOption(opt);
              setExtraQuantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              // setcustom(c=>c?c+`, ${opt.title}`:opt.title)
              setDefs((defs) =>
                defs?.length
                  ? {
                      ...defs,
                      [perimite?.title]: opt.title,
                    }
                  : { [perimite?.title]: opt.title }
              );
            }
          }
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    props.item && getDefaults();
  }, []);
  const getBagItems = async () => {
    const user = useSelector((state) => state.authReducer.userData);
    const bags = user?.bagItems;
    return bags;
  };

  const onAddToCart = async (item) => {
    try {
      if (auth?.currentUser?.uid) {
        const bagitems = user?.bagItems;
        const newOption = {
          price: item.price,
          calculatedPrice: item.price,
          image: item.item_image_url,
          itemId: item.id,
          specialInstructions: '',
          title: item.name,
          quantity: 1,
          customization: extraQuantity,
        };
        let items = [];
        // if (bagitems?.length > 0) {

        //   for (let index = 0; index < bagitems?.length; index++) {
        //     const element = bagitems[index];
        //     if (element.itemId == newOption.itemId) {
        //       element['quantity'] = element['quantity'] + 1;
        //       element['calculatedPrice'] = item.price * element['quantity'];
        //       items = [...bagitems];
        //     } else {
        //       items = [...bagitems, newOption];
        //     }

        //   }
        // } else {
        //   items = [...bagitems, newOption];
        // }
        items = [...bagitems, newOption];
        const updateDoc = await firestore.collection('restaurant').doc();
        await firestore
          .collection('users')
          .doc(token)
          .set(
            {
              bagItems: items,
            },
            { merge: true }
          )
          .then(() => {});

        await firestore
          .collection('restaurant')
          .doc('Restaurant')
          .set(
            {
              update_items_id: updateDoc.id,
            },
            { merge: true }
          )
          .then(() => {
            dispatch(setCart(items));
            const updatedUser = { ...user, bagItems: items };
            dispatch(setUser({ userData: updatedUser }));
            setAdded(true);
            setTimeout(() => {
              setAdded(false);
            }, 2000);
          });
      } else if (!auth?.currentUser?.uid) {
        navigate('/signup');
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const [dialog, setDialog] = React.useState(false);

  const { name, description, id, item_image_url, price } = props.item;

  const [t, i18n] = useTranslation();


  const removeFavorite = async () => {
    try {
      let arr = user?.favorites ? user?.favorites : [];
      const updatedFavorites = arr.filter((favoriteId) => favoriteId !== props.item?.id);
     

      await firestore
        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            favorites: updatedFavorites,
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, favorites: updatedFavorites };
          dispatch(setUser({ userData: updatedUser }));
          // Call the callback function to update emptyfav state in FavoriteItems
          props.updateEmptyFavState(
            updatedFavorites.length === 0 ? `${t("profilee.noFavItems")}` : ''
          );
        });
    } catch (error) {
      console.log('error', error);
    }
  };
  
  return (
    <Container maxWidth="lg" style={{ paddingLeft: '0px' }}>
      <Card style={{ width: '350px', paddingTop: '30px' }}>
        <Link
         to={
          `/menu`
       }
        >
          <CardActionArea>
            <CardMedia style={{ height: 240 }} image={item_image_url} />
            <CardContent style={{ height: '140px' }}>
              <Typography gutterBottom variant="h5" component="h2">
                {name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {description?.length > 80
                  ? description.slice(0, 80)
                  : description}
                {description?.length > 80 ? '...' : ''}
              </Typography>

            
              {defs &&
                !props.editItem &&
                Object?.keys(defs).map((key, index) => (
                  <Typography
                    key={'mc' + index}
                    variant="body1"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {key}: {defs[key]}
                  </Typography>
                ))}
            </CardContent>
          </CardActionArea>
        </Link>
        <div style={{ height: '10px' }} />

        <CardActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {props.editItem ? (
            <>
              <CustomButton
                onClick={() => props.editItem(props.item.id)}
                buttonText={`${t('profilee.editItem')}`}
                admin={true}
                bg={props.color}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2,
                }}
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton onClick={() => setDialog(true)} sx={{ p: 0 }}>
                  <Delete style={{ color: 'black' }} />
                </IconButton>
              </Box>
              <Dialog
                style={{ zIndex: 10 }}
                open={dialog}
                keepMounted
                onClose={() => {
                  setDialog(false);
                }}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {t('profilee.youSureDeleteAdress')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    style={{ color: 'var(--P2, rgb(171, 19, 0))' }}
                    onClick={() => setDialog(false)}
                  >
                    {t('profilee.cancel')}
                  </Button>
                  <Button
                    style={{ color: 'var(--P2, rgb(171, 19, 0))' }}
                    onClick={() => {
                      props.deleteItem(props.item.id);
                      setDialog(false);
                    }}
                  >
                    {t('profilee.delete')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : checkLicenses('checkout') ? (
            added ? (
              <p style={{ color: 'green', fontWeight: '500' }}>
                {t('profilee.addedToCart')}
              </p>
            ) : (
              //  (
              //   <div style={{marginTop:"20px"}}>
              //   <CustomButton
              //     onClick={() => onAddToCart(props.item)}
              //     buttonText={'Add to cart'}
              //     bg={props.color}
              //   />
              //   </div>
              // )
              <>
              {/* <CustomButton
                bg={props.color}
                buttonText={`${t('orderNow')}`}
                onClick={() => {

                  if (stores.length == 1) {
                    dispatch(
                      activeMenu(stores[0].id?.active_menu_now)
                    );
                    dispatch(
                      allactiveMenues(stores[0].id?.all_active_menus)
                    );
                    dispatch(menues(stores[0].id?.menus));
                  }
                  // if (menuDocs?.length === 0) {
                    navigate(`/menu`);
                  // } else {
                  //   navigate(`/menu-items/${props.item.id}`);
                  // }
                }}
                admin={false}
              /> */}
              <CustomButton
              bg={props.color}
              buttonText={`${t('profilee.delete')}`}
              onClick={removeFavorite}
              admin={false}
            />
</>
            )
          ) : (
            ''
          )}
          <div style={{ height: '5px' }} />
        </CardActions>
      </Card>
    </Container>
  );
}
export default MenuCard;
