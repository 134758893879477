import React, {useEffect,  useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
} from '@mui/material';
import { Field, Formik } from 'formik';
import Button from '@mui/material/Button';
import { environment } from '../../../environments/environment';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import Loading from 'react-fullscreen-loading';
import {
  auth,
  firestore,
  recaptchaVerifier,
  _customApp,
} from '../../../../../../libs/firebase-service/src';
import CustomPhoneNumber from '../PhoneNumber/PhoneInput';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
const PhoneNumberForm = ({
  onClose,
  open,
  user,
  phoneNumber,
  setPhoneNumber,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [verifiedNum, setVerifiedNum] = useState(false);
  const [err, setErr] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const userData = useSelector((state) => state.authReducer.userData);
  const dispatch = useDispatch();

  let phoneNumbert = phoneNumber.toString();
  phoneNumbert = phoneNumbert.replace(/\s/g, '');
  phoneNumbert = phoneNumbert.replace(/-/g, '');
  phoneNumbert = phoneNumbert.replace(/[{()}]/g, '');
  phoneNumbert = '+1' + phoneNumbert;
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const authUser = await auth.currentUser;
      const doc = await firestore.collection('users').doc(authUser?.uid);
      await firestore
        .collection('users')
        .doc(doc.id)
        .set(
          {
            phoneNumber: phoneNumbert,
          },
          { merge: true }
        )
        .then(async () => {
          await getUser();
          setErr('');
          const updatedUser = { ...userData, phoneNumber: phoneNumbert };
          dispatch(setUser({ userData: updatedUser }));
        });
    } catch (e) {
      console.log('erorr', e.message);
      setErr(e.message);
      setLoading(false);
    }
  };

  const getUser = async () => {
    // setIsFirst(true)
    handleSubmit();
  };

  const requestOTP = async (e) => {
    setError('');
    if (phoneNumbert.length < 12) {
      setError('Please enter a valid phone number');

      e.preventDefault();
    } else {
      const { docs } = await firestore
        .collection('users')
        .where('phoneNumber', '==', phoneNumbert)
        .get();
      if (docs.length > 0) {
        setError('This number is already used');
      } else {
        setErr('');
        e.preventDefault();
        setExpandForm(true);
        geterate();
        if (user.phoneNumber !== null) {
          let hasPhoneProvider = false;
          auth?.currentUser?.providerData.map((provider) => {
            if (provider?.providerId === 'phone') {
              hasPhoneProvider = true;
            }
          });
          if (hasPhoneProvider)
            await auth?.currentUser
              ?.unlink('phone')
              .then(() => { })
              .catch((error) => {
                setLoading(false);
                console.log(error, 'error provider');
              });
        }

        let appvarifier = window.recaptchaVerifier;
        await auth?.currentUser
          .linkWithPhoneNumber(phoneNumbert, appvarifier)
          .then((confirmationResult, result) => {
            window.confirmationResult = confirmationResult;
            setErr('');
          })
          .catch((error) => {
            console.log('rechpecha error', error.message);
            setErr('Please, Reload the page to Verfiy again');
          });
      }
    }
  };
  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);
    if (OTP?.length === 6) {
      setLoading(true);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(OTP)
        .then(async (result) => {
          setLoading(true);
          const authUser = await auth.currentUser;
          const doc = await firestore.collection('users').doc(authUser?.uid);
          await firestore
            .collection('users')
            .doc(authUser?.uid)
            .set(
              {
                phoneNumber: phoneNumbert,
              },
              { merge: true }
            )
            .then(async () => {
              setErr('');
              const updatedUser = { ...userData, phoneNumber: phoneNumbert };
              dispatch(setUser({ userData: updatedUser }));
              setExpandForm(false);
            });
          // window.location.reload()
          // User signed in successfully.
          navigate(`/profile`);
          setErr('');
          // setVerifiedNum(true);
          onClose();
          setLoading(false);
          setPhoneNumber('');
        })
        .catch((e) => {
          setLoading(false);
          console.warn(e.message);
          let notCorrect =
            'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.';
          e.message === notCorrect
            ? setErr('Not correct. please verfiy your number again ')
            : setErr(e.message);
        })
        .catch((err) => {
          setLoading(false);
          setErr('Something went wrong');
        });
    } else setErr('Please enter 6 numbers');
  };

  const geterate = () => {
    window.recaptchaVerifier = new recaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      _customApp
    );
  };
  const [t, i18n] = useTranslation();

  const resetForm = () => {
    setPhoneNumber(''); // Clear the phone number field
    setOTP(''); // Clear the OTP field
    setError(''); // Clear the error message
    setErr(''); // Clear any other error messages
    setExpandForm(false); // Close the expanded form
  };

  // Add an effect to reset the form when the dialog is closed
  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);
  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          resetForm(); 
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}

      >
        <DialogTitle id={'title'} style={{ padding: '30px' }}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {t("profilee.changePhone")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              fullName: '',
              phoneNumber: '',
              email: '',
            }}
            // validationSchema={validateSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleSubmit,
              values,
              handleChange,
              handleBlur,
            }) => (
              <div>
                <Grid spacing={3} container>
                  <Grid
                    item
                    justify="space-around"
                    container
                    xs={12}
                    spacing={1}
                    style={{
                      // flexDirection: xsDown ? 'row' : 'unset',
                      marginTop: '0px',
                    }}
                  >
                    <Grid item xs={12} lg={12}>
                      <form>
                        <Field
                          dir="initial"
                          fullWidth
                          as={CustomPhoneNumber}
                          variant="outlined"
                          name="phoneNumber"
                          label={i18n.language === 'ar' ? "" : t("profilee.addYourNumber")}
                          required
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          value={phoneNumber}
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                fontSize="small"
                                style={{ color: 'black' }}
                              >
                                <PlusOneIcon
                                  fontSize="small"
                                  style={{ color: 'black' }}
                                />
                              </IconButton>
                            ),
                          }}
                          sx={{
                            borderColor: 'gray',
                            '& label.Mui-focused': {
                              color: 'black',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'gray',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'gray',
                              },
                            },
                          }}
                        />
                        <Button
                          size="large"
                          onClick={requestOTP}
                          variant="contained"
                          style={{
                            backgroundColor: environment.mainColor,
                            marginTop: '10px',
                          }}
                        >
                          {t("profilee.verifyPhoneNumber")}
                        </Button>
                        <div style={{ height: '30px' }} />
                        {expandForm === true ? (
                          <>
                            <div>
                              <ReactCodeInput
                                type="text"
                                onChange={(pinCode) => {
                                  setOTP(pinCode);
                                }}
                                fields={6}
                              />
                            </div>
                            {/* <TextField
                              fullWidth
                              variant="outlined"
                              label="please enter your verification number *"
                              helperText={touched.OTP && errors.OTP}
                              type="number"
                              id="otpInput"
                              value={OTP}
                              className={classes.input}
                              inputProps={{ maxLength: 5 }}
                              onWheel={(e) => e.target.blur()}
                              onBlur={handleBlur('OTP')}
                              onChange={(e) => {
                                setOTP(e.target.value);
                              }}
                              sx={{
                                '& label.Mui-focused': {
                                  color: 'black',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'gray',
                                  },
                                },
                              }}
                            /> */}
                            <Typography
                              variant="body"
                              style={{
                                color: 'red',
                                margin: '15px',
                                fontSize: 15,
                              }}
                            >
                              {err}
                            </Typography>
                            <br />
                            <Button
                              size="large"
                              onClick={verifyOTP}
                              variant="contained"
                              style={{
                                backgroundColor: environment.mainColor,
                                marginTop: '10px',
                              }}
                            >
                              {t("profilee.save")}
                            </Button>
                          </>
                        ) : null}
                        <Typography
                          variant="body"
                          style={{
                            color: 'red',
                            margin: '15px',
                            fontSize: 15,
                          }}
                        >
                          {error}
                        </Typography>
                        <Grid item md={12} xs={12}></Grid>

                        <div id="recaptcha-container"></div>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                ></div>
              </div>
            )}
          </Formik>
        </DialogContent>
        <Loading
          loading={!!loading}
          background="rgba(86, 100, 210, 0.1)"
          loaderColor={environment.mainColor}
        />
      </Dialog>
    </div>
  );
};

export default PhoneNumberForm;
