import { Box, Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import backgroundHomeSchool from '../../../assets/backgroundhomeschool.png';
import footer from '../../../assets/background.png';
import popschool from '../../../assets/popschool.png';
import { environment } from '../../../environments/environment';
import HomeCardTQ from '../../components/Homepage/HomeCardTQ';
import { CustomButton } from '../../../../../../libs/components/src';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';

function PopularSection({ items, modifiers }) {
  const [t] = useTranslation();
  const theme=useTheme()
  const navigate=useNavigate()
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));


  const handleMouseEnter = (index) => {
    setHoveredItemIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredItemIndex(null);
  };


  return (
    <div
      style={{
        position: 'relative',
        backgroundImage:
          environment.restaurantId === 'elmbrookschools' ||
          environment.restaurantId === 'alyousef'|| environment.restaurantId === 'filfil'
            ? `url(${backgroundHomeSchool})`
            : `url(${footer})`,
        paddingTop: '60px',
        paddingBottom: '90px',
      }}
    >
      {environment.restaurantId !== 'elmbrookschools' ? (
        <>
          {' '}
          {items?.length > 0 && (
            <Container maxWidth={'xl'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '12px',
                  // marginTop: '60px',
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: environment.mainColor,
                    fontWeight: 'bold',
                    fontFamily: `Hanalei Fill, cursive`,
                    direction: 'rtl',
                    textAlign: 'right',
                    marginBottom: '2rem',
                  }}
                >
                  {t('popularHome')}
                </Typography>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '60%',
                  }}
                >
                  <span
                    className="dot"
                    style={{ backgroundColor: environment.mainColor }}
                  ></span>

                  <Divider
                    style={{
                      marginBottom: '5px',
                      marginTop: '5px',
                      height: '2px',
                      backgroundColor: environment.mainColor,
                      width: '50%',
                      marginLeft: '8px',
                      marginRight: '8px',
                    }}
                  />
                  <span
                    className="dot"
                    style={{ backgroundColor: environment.mainColor }}
                  ></span>
                </div>
              </div>
              <Grid lg={4}>
                {/* <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: '12px',
        marginTop: '60px',
      }}
    >
      <span
        className="dot"
        style={{ backgroundColor: environment.mainColor }}
      ></span>
    </div> */}
              </Grid>

              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '12px',
                  direction: 'rtl',
                  textAlign: 'right',
                  color: '#808080',
                }}
              >
                <Typography variant="h6">{t('popularHomeDetails')}</Typography>
              </div>

              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={3}
                sx={{ display: { xs: 'contents', md: 'flex', lg: 'flex' } }}
              >
                {items.map((item, index) =>
                  index <= 2 &&
                  item?.is_popular &&
                  item?.item_state === 'available' ? (
                    <Grid
                      item
                      lg={3}
                      md={4}
                      sm={12}
                      style={{ paddingLeft: '0px' }}
                      key={index + 'id'}
                    >
                      {environment.restaurantId === 'istanbulDk' ||
                      environment.restaurantId === 'alyousef' ||
                      environment.restaurantId === 'filfil' ||
                      environment.restaurantId === 'elmbrookschools' ||
                      environment.restaurantId === 'ttDemo' ? (
                        <HomeCardTQ item={item} modifiers={modifiers} />
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  ) : (
                    ''
                  )
                )}
              </Grid>
            </Container>
          )}{' '}
        </>
      ) : (
        <>
          {' '}
          {items?.length > 0 && (
            <Container maxWidth={'xl'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '12px',
                  // marginTop: '60px',
                }}
              >
                <Typography
                  variant="h2"
                  style={{
                    color: environment.primaryColor,
                    fontWeight: 'bold',
                    fontFamily: `Hanalei Fill, cursive`,
                    direction: 'rtl',
                    textAlign: 'center',
                  }}
                >
                  {t('popularHome')}
                </Typography>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <img src={popschool} alt={'popular'} />
              </div>

              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '12px',
                  direction: 'rtl',
                  textAlign: 'right',
                  marginBottom: '120px',
                  color: 'gray',
                }}
              >
                <Typography variant="h6">{t('popularHomeDetails')}</Typography>
              </div>

              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={1}
                sx={{ display: { xs: 'contents', md: 'flex', lg: 'flex' } }}
              >
                {items.map((item, index) =>
                  index <= 3 &&
                  item?.is_popular &&
                  item?.item_state === 'available' ? (
                    <Grid item lg={3} md={4} sm={12} key={index + 'id'}>
                      <div
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Box
                          sx={{
                            background:
                              hoveredItemIndex === index
                                ? environment.primaryColor
                                : 'rgba(199, 161, 122, 0.18)',
                            cursor: 'pointer',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '20px',
                            width: '90%',
                            height: mdDown
                              ? hoveredItemIndex === index
                                ? '350px'
                                : '300px'
                              : hoveredItemIndex === index
                              ? '300px'
                              : '250px',
                          }}
                        >
                          <div style={{ width: '50%' }}>
                            <img
                              src={item.item_image_url}
                              alt={item.title}
                              style={{
                                width: '100%',
                                height: 'auto',
                                marginTop: mdDown ? '0px' : '-80px',
                              }}
                            />
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <Typography
                              variant="h4"
                              sx={{
                                color:
                                  hoveredItemIndex === index
                                    ? 'white'
                                    : 'rgba(185, 141, 88, 1)',
                                textAlign: 'center',
                                marginTop: '30px',
                              }}
                            >
                              {item.name}
                            </Typography>
                            {hoveredItemIndex === index && (
                              <div
                                style={{
                                  marginTop: '20px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  style={{
                                    color: environment.mainColor,
                                    backgroundColor: 'white',
                                    fontWeight: 'bold',
                                    borderRadius: '20px',
                                  }}
                                  variant="contained"
                                  onClick={() => navigate(`/menu`)}
                                  admin={false}
                                >
                                  {t('orderNow')}
                                </Button>
                              </div>
                            )}
                          </div>
                        </Box>
                      </div>
                    </Grid>
                  ) : (
                    ''
                  )
                )}
              </Grid>
            </Container>
          )}{' '}
        </>
      )}
    </div>
  );
}
export default PopularSection;
