import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container } from '@mui/material';


const BlogDetails = () => {

const [blogDetails, setBlogDetails] = useState(null);


   const { id } = useParams();

  useEffect(() => {
    axios.get(`https://cms.zetaton.dev/api/Taqwa-Blog-Details?filters[blogName][$eq]=${id}`)
      .then(response => {
        setBlogDetails(response.data.data[0]);
        console.log(response.data.data[0]?.attributes?.Editor, 'reesssss&&&&&&&')
      })
      .catch(error => console.error('Error fetching blog details:', error));
  }, [id]);

  return (
    <>
    <div style={{ marginTop: '92px' }} />

    <div>
         <Container style={{ marginTop:'10px' }}>
      {blogDetails ? (
        <div dangerouslySetInnerHTML={{ __html: blogDetails.attributes?.Editor }} />
      ) : (
        <p>Loading...</p>
      )}
      </Container>
    </div>
    </>

  );
};

export default BlogDetails;
