import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CustomButton } from '@tabletuck/components';
import { Navigate, useNavigate } from 'react-router-dom';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { environment } from '../../../environments/environment';
import { auth } from '../../../../../../libs/firebase-service/src';

function SuccessDialog({ open ,setOpenSuccess}) {
  const navigate = useNavigate();
  const onClose = () => {
    setOpenSuccess(false);
  };
  const navigateToTheHistory = () => {
    navigate(`/profile/${auth?.currentUser?.uid}?type=order`);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle>
        <div
          style={{
            position: 'relative',
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '25px',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              cursor: 'pointer',
              marginLeft: '15px',
              color: 'rgb(160, 25, 19)',
            }}
            onClick={onClose}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
           
          }}
        >
          <img
            alt="category"
            width={'50px'}
            height={'50px'}
            src={require('./success-icon-23194.png')}
          />
          <Typography
            variant="h5"
            style={{
              margin: 20,
              color: 'gray',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Your order has been accepted
          </Typography> 
         
             <Typography
            variant="body1"
            style={{
            
              color: 'gray',
              alignSelf: 'center',
              textAlign: 'center',
              marginTop:'2px',
              marginBottom:'7px'
            }}
          >To track your order check your order history
          </Typography>
          <CustomButton
            buttonText={`Take me to orders history`}
            onClick={navigateToTheHistory}
            bg={environment.mainColor}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessDialog;
