import { auth, firestore } from '@tabletuck/firebase-services';
import { handleToaster } from '../../Utils/UtilsFunctions';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import axios from 'axios';
export const checkCoupon = async (
  token,
  code,
  setRes,
  setDiscount,
  setIsverify
) => {
  try {
    setRes('');
    const user = await firestore.collection('users').doc(token).get();
    const items = user.data().bagItems;
    const res = await axios.post(
      `${environment?.apiUrl}/verifiedCoupon?restaurantId=${environment.restaurantId}`,
      {
        code,
        items,
      }
    );
    const { msg, isVerify, more_data, discount } = res.data;
    discount && setDiscount(discount);
    setIsverify(isVerify);
    if (isVerify) {
      setRes('Success');
    } else if (msg === 'should be a minimum total price') {
      setRes(`Sorry.. the minimum price for this coupon is ${more_data}`);
    } else if (msg === 'Should be a minimum quantity') {
      setRes(`Sorry.. the minimum quantity for this coupon is ${more_data}`);
    } else setRes(msg);
    return { isVerify, discount };
  } catch (e) {
    console.log('error adding copun', e.message);
    setRes('failed try later');
  }
};

export const getUserAddress = async (token, callback, handleErr) => {
  try {
    let AdressRef = firestore
      .collection('users')
      .doc(token)
      .collection('addresses');
    const AdressesList = await AdressRef.get();
    let list = [];
    for (let index = 0; index < AdressesList.docs.length; index++) {
      let element = AdressesList.docs[index].data();
      element.id = AdressesList.docs[index].id;
      list.push(element);
    }
    callback(list);
  } catch (e) {
    handleErr(e.message);
  }
};
export const validateUserAddress = async (token, address, addressId) => {
  console.log(token, 'token');

  const { city, displayName, state, route, streetNumber, lat, lng, zipCode } =
    address;
  try {
    const tokenId = await auth?.currentUser.getIdToken().then((token) => token);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: tokenId,
    };

    const { data } = await axios.post(
      `${environment?.apiUrl}/getStores?restaurantId=${environment.restaurantId}`,
      {
        city,
        state,
        displayName,
        route,
        streetNumber,
        lat,
        lng,
        zipCode,
      },
      { headers }
    );
    if (!data.delivery.length) {
      throw new Error('This address is out of service.');
    } else {
      await firestore.doc(`users/${token}`).set(
        {
          defaultDeliveryAddress: addressId,
        },
        {
          merge: true,
        }
      );
      await firestore
        .doc(`users/${token}`)
        .collection('addresses')
        .doc(addressId)
        .set(
          {
            delivery_store_id: data.delivery[0].id,
          },
          {
            merge: true,
          }
        );
      const doc = await firestore
        .collection('users')
        .doc(token)
        .collection('addresses')
        .doc(addressId)
        .get();
      if (doc?.exists) {
        return {
          displayName: doc.data().displayName,
          storeId: data.delivery[0].id,
        };
      }
    }
  } catch (e) {
    console.log('message', e.message);
    //call error (e)
    return e.message;
  }
};
export const getStrores = async (handleErr, callback, storeIdRedux) => {
  try {
    let observer = await firestore.collection(`stores`).onSnapshot((docs) => {
      let stores = [];
      docs.forEach((doc) => {
        stores.push(doc.data());
      });
      callback(stores);
    });
    return () => {
      observer();
    };
  } catch (e) {
    console.log('error', e.message);
    handleErr(e.message);
  }
};
// export const getUser = async (token) => {
//   const user = await firestore.collection('users').doc(token).get();
//   return user.data();
// };
export const setDefaultStore = async (
  token,
  restId,
  text,
  callBack,
  pickup,
  enable_pickup,
  dinein,
  enableDinin,
  storeaddress,
  onClickAdress,
  placeId
) => {
  try {
    firestore
      .doc(`users/${auth?.currentUser?.uid}`)
      .set(
        {
          defaultStore: restId,
        },
        { merge: true }
      )
      .then(() => {
        console.log('updated');
        callBack(text);
        enable_pickup(pickup);
        enableDinin(dinein);
        onClickAdress(storeaddress)
      })
      .catch((error)=>{
        console.log(error)
        // handleToaster("error", error?.response?.data ||"error in select store")
      })
  } catch (e) {
    callBack(e.message);
  }
};

export const makeAPICall = async (restId, placeId, userId) => {
  const requestData = {
    uid: userId,
    storeId: restId,
    addressId: placeId,
  };
  console.log(requestData , 'requestData')
  try {
    const response = await fetch(
      `${environment?.apiUrl}/localDeliveryFee?restaurantId=${environment.restaurantId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      }
    );
    const responseData = await response.json();
    console.log('API Response for store:', restId, responseData);
    // if (responseData.message) {
    //   throw new Error(responseData.message);
    //   // Throw an error if the API response contains an error message
    // }
    if (responseData.message) {
      return { error: responseData.message }; // Return the error message
    }
    return responseData.fee !== undefined ? responseData.fee : 0;
  } catch (error) {
    console.error('Error:', error.message);
    return { error: error.message };
  }
};
