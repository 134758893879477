import React, { useEffect, useState } from 'react';
import { Grid, Container, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import banner from '../../../assets/bannerblogs.png';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const [pageContent, setPageContent] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(8);
  const [blogsContent, setBlogsContent] = useState([]);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await axios.get('https://cms.zetaton.dev/api/taqwa-blog-cards');

        const sortedData = response.data.data.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
        setPageContent((prevContent) => [...prevContent, ...sortedData]);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageContent();
  }, []);

  useEffect(() => {
    const fetchPageBlogs = async () => {
      try {
        const response = await axios.get(
          `https://cms.zetaton.dev/api/Taqwa-Blog-Details`
        );
        console.log(response, 'response');
        setBlogsContent((prevContent) => [
          ...prevContent,
          ...response.data.data,
        ]); // Append new data to existing data
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageBlogs();
  }, []); // Fetch new data whenever currentPage changes

  const navigate = useNavigate();

  // Existing useEffects and other logic

  const handleCardClick = (blogId) => {
    console.log(blogId, 'blogId');
    // Find if there's a matching pageContent id
    const matchingContent = blogsContent.find(
      (content) => content.attributes?.blogName === blogId
    );
    if (matchingContent) {
      // Navigate to the blog page
      // history.push(`/blogs/${blogId}`);
      navigate(`/blogs/${blogId}`);
    } else {
      // Handle cases where no matching id is found or show an error
      console.error('No matching content found.');
    }
  };
  const handleSeeMore = () => {
    setItemsToShow((prevItemsToShow) => prevItemsToShow + 8); // Increase by 8
  };

  return (
    <>
      <div style={{ marginTop: '82px' }} />

      <Container style={{ marginTop: '10px' }}>
        <img src={banner} alt="tes" style={{ width: '100%' }} />
        <BlogHeader />
        <Grid container spacing={2} style={{ textAlign: 'center' }}>
          {pageContent.slice(0, itemsToShow).map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <div
                onClick={() => handleCardClick(item?.attributes?.blogName)}
                dangerouslySetInnerHTML={{ __html: item.attributes.Editor }}
              />
            </Grid>
          ))}
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20PX',
          }}
        >
          {itemsToShow < pageContent.length && (
            <Button
              style={{ border: '1px solid gray', color: 'gray' }}
              onClick={handleSeeMore}
            >
              See More
            </Button>
          )}
        </div>
      </Container>
    </>
  );
};

export default Blogs;

export const BlogHeader = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '40px 0',
        backgroundColor: '#f8f8f8',
      }}
    >
      <Typography
        variant="h5"
        display="block"
        gutterBottom
        sx={{
          color: '#FF6347',
          letterSpacing: '2px',
          fontSize: '24px',
        }}
      >
        BLOGS
      </Typography>
      <Typography
        variant="h2"
        component="div"
        gutterBottom
        sx={{
          fontWeight: 'bold',
        }}
      >
        Our Blogs
      </Typography>
    </Box>
  );
};
